export const FacebookIcon = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Social icons/Facebook/White/True/Default"
      clipPath="url(#clip0_12927_3627)"
    >
      <path
        id="Vector"
        d="M32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 23.9859 5.85094 30.6053 13.5 31.8056V20.625H9.4375V16H13.5V12.475C13.5 8.465 15.8888 6.25 19.5434 6.25C21.2934 6.25 23.125 6.5625 23.125 6.5625V10.5H21.1075C19.12 10.5 18.5 11.7334 18.5 13V16H22.9375L22.2281 20.625H18.5V31.8056C26.1491 30.6053 32 23.9859 32 16Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_12927_3627">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
