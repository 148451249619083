const Verse = (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Frame 1000004024">
      <rect x="0.5" width="24" height="24" rx="4" fill="#0EDAB5" />
      <g id="VS">
        <path
          id="Vector"
          d="M17.2503 16.4278C16.686 16.4278 16.1695 16.3583 15.7007 16.2194C15.2406 16.0805 14.8282 15.8765 14.4636 15.6074C14.1077 15.3383 13.7865 15.0127 13.5 14.6308L14.724 13.2374C15.1494 13.8277 15.5705 14.2314 15.9872 14.4484C16.4125 14.6568 16.8726 14.761 17.3675 14.761C17.6366 14.761 17.8753 14.7262 18.0837 14.6568C18.3007 14.5787 18.47 14.4702 18.5915 14.3313C18.713 14.1924 18.7738 14.0274 18.7738 13.8364C18.7738 13.6975 18.7434 13.5717 18.6827 13.4588C18.6219 13.3373 18.5351 13.2331 18.4222 13.1463C18.3094 13.0508 18.1748 12.9683 18.0186 12.8989C17.8623 12.8207 17.6887 12.7556 17.4977 12.7035C17.3067 12.6428 17.0983 12.595 16.8726 12.5603C16.3865 12.4474 15.9611 12.3129 15.5965 12.1566C15.2319 11.9917 14.9281 11.792 14.685 11.5576C14.4419 11.3145 14.2596 11.0411 14.1381 10.7372C14.0252 10.4334 13.9688 10.0905 13.9688 9.70853C13.9688 9.31787 14.0556 8.95761 14.2292 8.62772C14.4028 8.28915 14.6416 7.99834 14.9454 7.75526C15.2579 7.51219 15.6139 7.32554 16.0132 7.19533C16.4212 7.06511 16.8509 7 17.3024 7C17.8579 7 18.3441 7.06511 18.7608 7.19533C19.1775 7.31686 19.5378 7.49483 19.8416 7.72922C20.1541 7.96361 20.4146 8.25009 20.6229 8.58866L19.3858 9.78666C19.2035 9.54358 19.0039 9.33958 18.7868 9.17463C18.5785 9.00969 18.3484 8.88816 18.0967 8.81002C17.8536 8.72321 17.6019 8.67981 17.3414 8.67981C17.0549 8.67981 16.8075 8.71887 16.5992 8.797C16.3908 8.86645 16.2259 8.97063 16.1044 9.10953C15.9915 9.24842 15.9351 9.41771 15.9351 9.61737C15.9351 9.77364 15.9741 9.91253 16.0523 10.0341C16.1304 10.1556 16.2433 10.2641 16.3908 10.3596C16.5384 10.4464 16.7164 10.5246 16.9247 10.594C17.1331 10.6635 17.3631 10.7242 17.6149 10.7763C18.0923 10.8718 18.5221 10.9977 18.904 11.1539C19.286 11.3102 19.6115 11.5012 19.8807 11.7269C20.1585 11.9439 20.3711 12.2044 20.5187 12.5082C20.6663 12.8034 20.7401 13.1376 20.7401 13.5109C20.7401 14.1272 20.5925 14.6525 20.2974 15.0865C20.0022 15.5206 19.5942 15.8548 19.0733 16.0892C18.5524 16.3149 17.9448 16.4278 17.2503 16.4278Z"
          fill="white"
        />
        <path
          id="Vector_2"
          d="M8.13307 16.4602L4.5 7.34497H6.57046L8.47164 12.2802C8.57581 12.5493 8.66262 12.7881 8.73207 12.9964C8.8102 13.1961 8.87531 13.3871 8.9274 13.5694C8.98817 13.743 9.04459 13.9166 9.09668 14.0902C9.14877 14.2552 9.20086 14.4375 9.25294 14.6372L8.87531 14.6502C8.94476 14.3811 9.01421 14.1336 9.08366 13.9079C9.15311 13.6735 9.23124 13.4305 9.31805 13.1787C9.40486 12.927 9.51338 12.6275 9.6436 12.2802L11.4406 7.34497H13.498L9.83892 16.4602H8.13307Z"
          fill="white"
        />
      </g>
    </g>
  </svg>
);

export default Verse;
