const HertzLogo = (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.3125"
      y="0.3125"
      width="39.375"
      height="39.375"
      rx="2.1875"
      fill="#FFD100"
      stroke="#D5D7D9"
      strokeWidth="0.625"
    />
    <path
      d="M34.9102 26H3.32397L3.68658 24.2923L35.2746 24.2915L34.9102 26Z"
      fill="white"
    />
    <path
      d="M27.3374 21.3558C27.0552 21.0521 27.224 20.4475 27.3686 19.7651L27.8616 17.4475H29.8881L30.2552 15.7219H28.2269L28.5869 14H26.6765L25.4243 19.9232C25.2359 20.8083 25.1653 21.5004 25.5485 22.2462C25.9057 22.9393 27.1257 23.6011 28.6699 23.1831L29.0111 21.5817C27.9644 21.7112 27.5437 21.5755 27.3374 21.3558Z"
      fill="black"
    />
    <path
      d="M11.1576 14L10.2457 18.2781H6.89289L7.80209 14H5.87384L3.92415 23.1831H5.85062L6.52403 20.0009H9.8786L9.2034 23.1831H11.1147L13.0662 14H11.1576Z"
      fill="black"
    />
    <path
      d="M22.1662 16.3703C21.4972 16.9285 21.1177 17.66 20.8712 18.8139L19.9432 23.1822H21.8554L22.6262 19.5597C22.8084 18.7121 22.9477 18.1825 23.2656 17.8565C23.7033 17.409 24.3383 17.384 25.1001 17.4474L25.4681 15.7219C24.1239 15.3808 22.9504 15.7157 22.1662 16.3703Z"
      fill="black"
    />
    <path
      d="M37.0912 15.7219H31.1224L30.7571 17.4475H34.2198L29.889 21.5237L29.5371 23.1831H35.505L35.873 21.4585H32.3907L36.7429 17.3635L37.0912 15.7219Z"
      fill="black"
    />
    <path
      d="M16.9477 15.5853C16.3019 15.5853 15.4186 15.7854 14.5505 16.4668C12.5937 18.0021 11.622 23.3188 15.8241 23.3188C16.9137 23.3188 18.1257 22.975 18.9661 22.34L18.2061 21.0557C17.5702 21.5451 14.3665 22.6088 14.7041 20.0125H19.7119C19.7137 20.0071 20.3505 17.9315 19.4323 16.6758C18.6509 15.604 17.2102 15.5853 16.9477 15.5853ZM18.1909 18.4317H15.1257C15.2338 18.0191 15.8849 17.0036 17.0933 17.1143C18.3936 17.2349 18.1909 18.4317 18.1909 18.4317Z"
      fill="black"
    />
  </svg>
);

export { HertzLogo };
