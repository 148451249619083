import { add, init, track } from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { createContext, PropsWithChildren, useEffect } from 'react';

interface AmplitudeContextType {
  trackAmplitudeEvent: (
    eventName: string,
    eventProperties: Record<string, unknown>
  ) => void;
}

export const AmplitudeContext = createContext<AmplitudeContextType | null>(
  null
);

export const AmplitudeContextProvider = ({ children }: PropsWithChildren) => {
  const sessionReplayTracking = sessionReplayPlugin({ sampleRate: 1 });

  useEffect(() => {
    init(
      process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY as string,
      'session replay users',
      {
        defaultTracking: {
          sessions: true,
        },
      }
    );
    add(sessionReplayTracking);
  }, [sessionReplayTracking]);

  const trackAmplitudeEvent = (
    eventName: string,
    eventProperties: Record<string, unknown>
  ) => {
    track(eventName, eventProperties);
  };

  return (
    <AmplitudeContext.Provider
      value={{
        trackAmplitudeEvent,
      }}
    >
      {children}
    </AmplitudeContext.Provider>
  );
};
