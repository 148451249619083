export const TictokIcon = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Social icons" clipPath="url(#clip0_12838_2366)">
      <path
        id="Exclude"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9999 31.8401C24.7481 31.8401 31.8399 24.7483 31.8399 16.0001C31.8399 7.25187 24.7481 0.160065 15.9999 0.160065C7.25172 0.160065 0.159912 7.25187 0.159912 16.0001C0.159912 24.7483 7.25172 31.8401 15.9999 31.8401ZM24.2133 14.1676C24.0573 14.182 23.9001 14.1916 23.7417 14.1916C21.9477 14.1916 20.3709 13.27 19.4529 11.8744V19.7668C19.4529 22.9888 16.8405 25.6 13.6197 25.6C10.3989 25.5988 7.78647 22.9864 7.78647 19.7656C7.78647 16.5436 10.3989 13.9324 13.6197 13.9324C13.712 13.9324 13.8022 13.9385 13.892 13.9447C13.9213 13.9466 13.9505 13.9486 13.9797 13.9504V16.8256C13.9492 16.8219 13.9189 16.8178 13.8887 16.8137C13.8001 16.8016 13.7119 16.7896 13.6197 16.7896C11.9757 16.7896 10.6425 18.1228 10.6425 19.7668C10.6425 21.4108 11.9745 22.744 13.6197 22.744C15.2649 22.744 16.7169 21.448 16.7169 19.804C16.7169 19.738 16.7457 6.40001 16.7457 6.40001H19.4925C19.7505 8.85641 21.7341 10.7956 24.2133 10.9732V14.1676Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_12838_2366">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
