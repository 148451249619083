const List = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.1111 0H0.888889C0.397969 0 0 0.391751 0 0.875V6.125C0 6.60825 0.397969 7 0.888889 7H15.1111C15.602 7 16 6.60825 16 6.125V0.875C16 0.391751 15.602 0 15.1111 0Z"
      fill="#010044"
    />
    <path
      d="M15.1111 9H0.888889C0.397969 9 0 9.39175 0 9.875V15.125C0 15.6082 0.397969 16 0.888889 16H15.1111C15.602 16 16 15.6082 16 15.125V9.875C16 9.39175 15.602 9 15.1111 9Z"
      fill="#010044"
    />
  </svg>
);

export default List;
