const Danger = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_b_426_108514)">
      <rect
        x="14"
        y="14"
        width="14"
        height="14"
        rx="7"
        transform="rotate(-180 14 14)"
        fill="#FFEDEA"
      />
      <path
        d="M6.99805 4L7.0014 7.21028"
        stroke="#BA1A1A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.99805 10.1973L7.00503 10.1973"
        stroke="#BA1A1A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="13.5"
        y="13.5"
        width="13"
        height="13"
        rx="6.5"
        transform="rotate(-180 13.5 13.5)"
        stroke="#BA1A1A"
      />
    </g>
    <defs>
      <filter
        id="filter0_b_426_108514"
        x="-2"
        y="-2"
        width="18"
        height="18"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="1" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_426_108514"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_426_108514"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default Danger;
