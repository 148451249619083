import React from 'react';
import Wrapper from '@/components/atoms/legacy/Wrapper';
import NavBar from '@/components/organisms/NavBar';

const HeaderTemplate: React.FC = () => {
  return (
    <Wrapper className="relative border-b-[1px]">
      <NavBar id="main-nav" className="col-span-full" />
    </Wrapper>
  );
};

export default HeaderTemplate;
