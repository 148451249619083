export const HandShake = (
  <svg
    width="86"
    height="82"
    viewBox="0 0 86 82"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Frame 1000004008">
      <path
        id="Vector"
        d="M28.0503 78.5155L19.3938 72.8818C16.5385 71.0249 14.8003 67.7647 14.8003 64.2603V53.6421C14.8003 50.012 16.6642 46.6541 19.68 44.8391L27.3242 40.3154C29.998 38.7377 32.9021 41.9699 31.0521 44.4621L51.3249 68.2046C53.4332 70.6758 50.9899 74.4945 48.009 73.3496C47.9671 73.3356 47.9252 73.3216 47.8903 73.3007C47.0037 75.4299 46.92 77.3218 42.7174 76.0861C41.4468 79.9117 38.2286 79.081 36.965 77.6638C36.8673 77.7057 36.8114 77.8035 36.7905 77.9291C36.609 78.8506 36.0156 79.6534 35.122 79.821C32.7066 80.2817 30.1166 79.8559 28.0433 78.5085L28.0503 78.5155Z"
        stroke="#2B2B2B"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M51.9465 13.6619L43.5065 13.5711L43.653 0L30.1797 19.5818L38.6198 19.6725L38.4871 31.9242L51.9465 13.6619Z"
        fill="#32FFC8"
      />
      <path
        id="Vector_3"
        d="M59.1507 34.7308L65.308 38.5843C68.3726 40.5041 70.2366 43.8689 70.2366 47.4851L69.4058 60.6862L56.833 66.5014L43.6528 51.3875L35.7713 56.1206C31.8968 58.4453 26.8844 57.4749 24.1688 53.8587C22.6749 51.8761 23.2543 49.0349 25.4045 47.7922L48.3301 34.5423C51.695 32.5946 55.8626 32.6714 59.1577 34.7308H59.1507Z"
        fill="#32FFC8"
        stroke="#2B2B2B"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_4"
        d="M14.7442 42.1725H2.35986V74.2433H14.7442V42.1725Z"
        fill="#32FFC8"
        stroke="#2B2B2B"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_5"
        d="M83.6401 33.6346H70.3552V68.0371H83.6401V33.6346Z"
        stroke="#2B2B2B"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
