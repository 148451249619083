const Jeep = (
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.53984 10.14H8.15488V17.0008C8.15488 18.1823 7.64928 20.6571 4.02048 20.6571C0.3936 20.6571 0 18.3512 0 17.0578V15.7906H2.53056V17.0258C2.53056 17.7855 3.03616 18.4888 3.99296 18.4888C4.94656 18.4888 5.53728 17.9544 5.53728 17.0552V10.14H5.53984ZM12.2054 13.0085C14.2577 13.009 16.0563 13.7968 16.0563 17.4232H11.191C11.191 18.5752 11.6691 19.0539 12.5126 19.0539C13.3574 19.0539 13.639 18.3794 13.639 18.3794H16.0307C16.0307 19.42 14.5971 20.8255 12.4314 20.7973C9.95776 20.7973 8.74816 18.9695 8.74816 17.0853C8.74816 15.2021 9.81657 13.0093 12.2054 13.0085ZM23.8739 17.4232C23.8739 13.7957 22.0742 13.0085 20.0211 13.0085H20.0179C17.6275 13.0085 16.56 15.2018 16.56 17.0853C16.56 18.9695 17.7696 20.7973 20.2438 20.7973C22.409 20.8255 23.8458 19.42 23.8458 18.3794H21.4554C21.4554 18.3794 21.1738 19.0539 20.3302 19.0539C19.4867 19.0539 19.008 18.5752 19.008 17.4232H23.8739ZM24.3232 22.9893V13.2888H26.7424V13.6831C26.7424 14.2731 27.0509 13.9359 27.079 13.852C27.1098 13.7675 27.6998 13.093 28.9094 13.093C30.119 13.093 32 13.9365 32 16.9176C32 19.2216 30.8762 20.9087 28.9344 20.9087C27.8656 20.9087 27.2486 20.4863 27.1078 20.3179C26.967 20.1496 26.7706 20.1215 26.7706 20.3179V22.9893H24.3232ZM12.3731 14.5829C13.273 14.5829 13.6659 15.2581 13.6659 15.9608H11.191C11.191 15.2018 11.5008 14.5829 12.3731 14.5829ZM21.4835 15.9608C21.4835 15.2581 21.0906 14.5829 20.1907 14.5829C19.3184 14.5829 19.0093 15.2018 19.0093 15.9608H21.4835ZM28.176 19.1947C26.9952 19.1947 26.6854 18.4357 26.7136 17.0008L26.713 17.0015C26.7386 15.5672 27.3018 14.9771 28.0634 14.9771C29.3005 14.9771 29.5264 15.9327 29.5264 16.9163C29.5264 17.9019 29.3568 19.1947 28.176 19.1947Z"
      fill="#080812"
    />
  </svg>
);

export default Jeep;
