import Image from '@/components/atoms/Image';
import { UserCircleIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';

interface AvatarProps {
  size: 24 | 32 | 36 | 40 | 64;
  /** 2 letters, like a user's initials. If it's falsy, render a default "User" icon */
  fallback?: string;
  imageIconSrc?: string;
}

export const Avatar: React.FC<AvatarProps> = ({
  size,
  fallback,
  imageIconSrc,
}) => {
  const containerSizeToPx = size;

  const renderContent = () => {
    if (imageIconSrc) {
      return (
        <Image
          aria-label="User avatar image"
          src={imageIconSrc}
          alt="User avatar"
          className="!relative rounded-full"
          fill
        />
      );
    }
    if (fallback) {
      return (
        <span
          className={clsx('text-center text-[#232323]', {
            'text-avatar-24': size === 24,
            'text-avatar-32': size === 32,
            'text-avatar-36': size === 36,
            'text-avatar-40': size === 40,
            'text-avatar-64': size === 64,
          })}
        >
          {fallback}
        </span>
      );
    }
    return (
      <UserCircleIcon width={containerSizeToPx} height={containerSizeToPx} />
    );
  };

  return (
    <div
      className={`flex items-center justify-center rounded-full bg-background-cloud w-[${containerSizeToPx}px] h-[${containerSizeToPx}px]`}
    >
      {renderContent()}
    </div>
  );
};
