import { GOOGLE_API_KEY } from '@/lib/constants';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { APIProvider as GoogleMapsAPIProvider } from '@vis.gl/react-google-maps';
import { FC, PropsWithChildren } from 'react';

const queryClient = new QueryClient();

export const IFrameEntryPoint: FC<PropsWithChildren> = ({ children }) => {
  return (
    <main>
      <QueryClientProvider client={queryClient}>
        <GoogleMapsAPIProvider apiKey={GOOGLE_API_KEY} libraries={['places']}>
          {children}
        </GoogleMapsAPIProvider>
      </QueryClientProvider>
    </main>
  );
};
