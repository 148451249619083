const Battery = (
  <svg
    width="10"
    height="20"
    viewBox="0 0 10 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.12113 3.34582C8.14599 3.27549 8.14599 3.19877 8.14599 3.12843V3.10286C8.14599 2.43152 7.56185 1.87526 6.83478 1.87526H6.22579V0.890625H3.81466V1.87526H3.13731C2.41024 1.87526 1.86961 2.43152 1.86961 3.10286V3.12843C1.86961 3.19877 1.89446 3.27549 1.89446 3.34582H1.54025C1.09904 3.34582 0.601901 3.51206 0.371974 3.85093C0.253904 3.99798 0.185547 4.16422 0.185547 4.38161V18.0898C0.185547 18.1601 0.210404 18.2368 0.210404 18.2816C0.328474 18.9082 0.956112 19.4836 1.54647 19.4836H8.47534C9.13405 19.4836 9.48205 18.7611 9.48205 18.0834V4.38161C9.48205 3.70387 9.13405 3.34582 8.47534 3.34582H8.12734H8.12113ZM5.61679 9.94416H7.04607L6.59864 10.526L4.58523 13.2242L4.33044 13.5374L3.7898 14.2344L4.67844 11.0567H2.62153L3.46045 9.95056L3.72145 9.59251L3.97623 9.25364L4.65358 8.36491L5.87157 6.77287L5.07615 9.63726L4.98294 9.95056H5.61679V9.94416Z"
      fill="#12D248"
    />
  </svg>
);

export default Battery;
