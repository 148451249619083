export const BlackApple = (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Social icons" clipPath="url(#clip0_12603_148426)">
      <path
        id="Vector"
        d="M21.5208 8.68242C21.3816 8.79043 18.9237 10.1754 18.9237 13.2549C18.9237 16.8168 22.0512 18.077 22.1448 18.1082C22.1304 18.185 21.648 19.8339 20.4959 21.5141C19.4686 22.9926 18.3957 24.4688 16.7635 24.4688C15.1313 24.4688 14.7113 23.5207 12.8271 23.5207C10.9909 23.5207 10.3381 24.5 8.84512 24.5C7.35217 24.5 6.31046 23.1319 5.11274 21.4517C3.7254 19.4787 2.60449 16.4136 2.60449 13.5045C2.60449 8.83843 5.6384 6.36379 8.62429 6.36379C10.2109 6.36379 11.5334 7.40549 12.5295 7.40549C13.4776 7.40549 14.9561 6.30138 16.7611 6.30138C17.4452 6.30138 19.903 6.36379 21.5208 8.68242ZM15.9042 4.32598C16.6507 3.44029 17.1788 2.21137 17.1788 0.982448C17.1788 0.812031 17.1643 0.639214 17.1331 0.5C15.9186 0.545605 14.4737 1.30888 13.6024 2.31938C12.9183 3.09706 12.2799 4.32598 12.2799 5.57171C12.2799 5.75893 12.3111 5.94614 12.3255 6.00615C12.4023 6.02055 12.5271 6.03735 12.6519 6.03735C13.7416 6.03735 15.1121 5.30768 15.9042 4.32598Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_12603_148426">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0.604492 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
