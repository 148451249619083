const SMSEdit = (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.0007 29.0416H9.91732C5.66732 29.0416 2.83398 26.9166 2.83398 21.9583V12.0416C2.83398 7.08325 5.66732 4.95825 9.91732 4.95825H24.084C28.334 4.95825 31.1673 7.08325 31.1673 12.0416V16.2916"
      stroke="#2E3132"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.0827 12.75L19.6485 16.2917C18.1893 17.4533 15.7952 17.4533 14.336 16.2917L9.91602 12.75"
      stroke="#2E3132"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.2143 20.9241L22.1993 25.9391C22.001 26.1374 21.8168 26.5057 21.7743 26.7749L21.5051 28.6874C21.406 29.3816 21.8876 29.8633 22.5818 29.7641L24.4943 29.4949C24.7635 29.4524 25.146 29.2682 25.3301 29.0699L30.3452 24.0549C31.2093 23.1908 31.6202 22.1849 30.3452 20.9099C29.0843 19.6491 28.0785 20.0599 27.2143 20.9241Z"
      stroke="#2E3132"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.4922 21.6465C26.9172 23.1765 28.1072 24.3665 29.6372 24.7915"
      stroke="#2E3132"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SMSEdit;
