const InstagramGreen = (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 28.2C21.75 28.2 19.8 26.4 19.8 24C19.8 21.75 21.6 19.8 24 19.8C26.25 19.8 28.2 21.6 28.2 24C28.2 26.25 26.25 28.2 24 28.2Z"
      fill="#0EDAB5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.1 13.8H18.9C17.7 13.95 17.1 14.1 16.65 14.25C16.05 14.4 15.6 14.7 15.15 15.15C14.7939 15.5061 14.6257 15.8622 14.4223 16.2926C14.3687 16.406 14.3126 16.5249 14.25 16.65C14.2268 16.7196 14.2 16.7928 14.1713 16.8713C14.0144 17.3 13.8 17.8856 13.8 18.9V29.1C13.95 30.3 14.1 30.9 14.25 31.35C14.4 31.95 14.7 32.4 15.15 32.85C15.5061 33.2061 15.8622 33.3743 16.2926 33.5777C16.4061 33.6313 16.5248 33.6874 16.65 33.75C16.7196 33.7732 16.7928 33.8 16.8713 33.8287C17.3 33.9856 17.8856 34.2 18.9 34.2H29.1C30.3 34.05 30.9 33.9 31.35 33.75C31.95 33.6 32.4 33.3 32.85 32.85C33.2061 32.4939 33.3743 32.1378 33.5777 31.7074C33.6313 31.5939 33.6874 31.4752 33.75 31.35C33.7732 31.2804 33.8 31.2072 33.8287 31.1287C33.9856 30.7 34.2 30.1144 34.2 29.1V18.9C34.05 17.7 33.9 17.1 33.75 16.65C33.6 16.05 33.3 15.6 32.85 15.15C32.4939 14.7939 32.1378 14.6257 31.7074 14.4223C31.5941 14.3688 31.475 14.3125 31.35 14.25C31.2804 14.2268 31.2072 14.2 31.1287 14.1713C30.7 14.0144 30.1144 13.8 29.1 13.8ZM24 17.55C20.4 17.55 17.55 20.4 17.55 24C17.55 27.6 20.4 30.45 24 30.45C27.6 30.45 30.45 27.6 30.45 24C30.45 20.4 27.6 17.55 24 17.55ZM32.1 17.4C32.1 18.2284 31.4284 18.9 30.6 18.9C29.7716 18.9 29.1 18.2284 29.1 17.4C29.1 16.5716 29.7716 15.9 30.6 15.9C31.4284 15.9 32.1 16.5716 32.1 17.4Z"
      fill="#0EDAB5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24ZM18.9 11.55H29.1C30.45 11.7 31.35 11.85 32.1 12.15C33 12.6 33.6 12.9 34.35 13.65C35.1 14.4 35.55 15.15 35.85 15.9C36.15 16.65 36.45 17.55 36.45 18.9V29.1C36.3 30.45 36.15 31.35 35.85 32.1C35.4 33 35.1 33.6 34.35 34.35C33.6 35.1 32.85 35.55 32.1 35.85C31.35 36.15 30.45 36.45 29.1 36.45H18.9C17.55 36.3 16.65 36.15 15.9 35.85C15 35.4 14.4 35.1 13.65 34.35C12.9 33.6 12.45 32.85 12.15 32.1C11.85 31.35 11.55 30.45 11.55 29.1V18.9C11.7 17.55 11.85 16.65 12.15 15.9C12.6 15 12.9 14.4 13.65 13.65C14.4 12.9 15.15 12.45 15.9 12.15C16.65 11.85 17.55 11.55 18.9 11.55Z"
      fill="#0EDAB5"
    />
  </svg>
);

export default InstagramGreen;
