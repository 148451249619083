import { ImageLoaderProps } from 'next/image';

export const cloudfrontLoader = ({ src, width }: ImageLoaderProps) => {
  const srcUrl = new URL(src);

  const cfDomain = 'https://d2ng44qkzrng60.cloudfront.net';
  const bucketName = srcUrl.host.split('.')[0];

  const url = `${cfDomain}/${bucketName}/${width}${srcUrl.pathname}`;

  return url;
};
