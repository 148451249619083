import React from 'react';

const UserProfile = (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.6865 12.6823C10.5698 12.6707 10.4298 12.6707 10.3015 12.6823C7.52482 12.589 5.31982 10.314 5.31982 7.51398C5.31982 4.65565 7.62982 2.33398 10.4998 2.33398C13.3582 2.33398 15.6798 4.65565 15.6798 7.51398C15.6682 10.314 13.4632 12.589 10.6865 12.6823Z"
      stroke="#9ECAFF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.1448 4.66602C21.4082 4.66602 23.2282 6.49768 23.2282 8.74935C23.2282 10.9543 21.4782 12.751 19.2965 12.8327C19.2032 12.821 19.0982 12.821 18.9932 12.8327"
      stroke="#9ECAFF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.85334 16.986C2.03001 18.876 2.03001 21.956 4.85334 23.8343C8.06167 25.981 13.3233 25.981 16.5317 23.8343C19.355 21.9443 19.355 18.8643 16.5317 16.986C13.335 14.851 8.07334 14.851 4.85334 16.986Z"
      stroke="#9ECAFF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.3965 23.334C22.2365 23.159 23.0298 22.8206 23.6831 22.319C25.5031 20.954 25.5031 18.7023 23.6831 17.3373C23.0415 16.8473 22.2598 16.5207 21.4315 16.334"
      stroke="#9ECAFF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default UserProfile;
