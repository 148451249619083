import { CognitoUser, EVUser, RawCognitoUser, RawEVUser } from './types';

export const parseCognitoUser = (
  rawCognitoUser: RawCognitoUser
): CognitoUser => {
  const { identities } = rawCognitoUser;
  const parsedIdentities = identities
    ? (JSON.parse(identities) as { providerName: string }[])
    : undefined;

  let fullName = rawCognitoUser.given_name || rawCognitoUser.name || '';
  if (rawCognitoUser.given_name && rawCognitoUser.family_name) {
    fullName = `${rawCognitoUser.given_name} ${rawCognitoUser.family_name}`;
  }

  return {
    email: rawCognitoUser.email as string,
    phone: rawCognitoUser.phone_number,
    firstName: rawCognitoUser.given_name,
    lastName: rawCognitoUser.family_name,
    fullName,
    picture: rawCognitoUser.picture,
    identityProvider: parsedIdentities?.[0].providerName,
  };
};

export const parseEVUser = (rawEVUser: RawEVUser): EVUser => {
  return {
    evId: rawEVUser.ev_id,
    phone: rawEVUser.phone,
    firstName: rawEVUser.first_name,
    lastName: rawEVUser.last_name,
    fullName: rawEVUser.full_name,
    customerEmail: rawEVUser.customer_email,
    profile: rawEVUser.profile,
    flaggedForDeletion: rawEVUser.flagged_for_deletion,
  };
};
