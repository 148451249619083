import { AnalyticsProvider } from '@/components/molecules/AnalyticsProvider';
import { AuthProvider } from '@/components/molecules/AuthProvider';
import { GoogleTagManager } from '@/components/molecules/GoogleTagMananger';
import ErrorBoundary from '@/components/organisms/ErrorBoundary/ErrorBoundary';
import { FooterTemplate } from '@/components/template/FooterTemplate';
import HeaderTemplate from '@/components/template/HeaderTemplate';
import { AmplitudeContextProvider } from '@/context/Amplitude';
import { CachedDataProvider } from '@/context/CachedData';
import { FullStoryContextProvider } from '@/context/FullStory';
import { PreviousUrlProvider } from '@/context/PreviousUrl';
import { SavedVehiclesProvider } from '@/context/SavedVehicles';
import { VehicleFilterSettingsProvider } from '@/context/VehicleFilterSettings';
import { ZipProvider } from '@/context/ZipContext';
import { AUTH_CONFIG, GOOGLE_API_KEY } from '@/lib/constants';
import { BasePageProps } from '@/types/page';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { APIProvider as GoogleMapsAPIProvider } from '@vis.gl/react-google-maps';
import { Amplify } from 'aws-amplify';
import { AwsRumConfig } from 'aws-rum-web';
import { useRouter } from 'next/compat/router';
import Head from 'next/head';
import { FC, PropsWithChildren, useEffect, useMemo } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const queryClient = new QueryClient();

Amplify.configure(AUTH_CONFIG, { ssr: true });

type MarketPlaceEntryPointProps = Pick<
  BasePageProps,
  'noHeader' | 'noFooter' | 'user'
>;

export const MarketPlaceEntryPoint: FC<
  PropsWithChildren<MarketPlaceEntryPointProps>
> = ({ children, noHeader, noFooter, user }) => {
  const toastBackgroundClass = useMemo(
    () => ({
      success: 'bg-paleGreen/90 border-green-dark',
      error: 'bg-paleRed/90 border-red-medium box',
      info: 'bg-brandMidnight/90 border-neutral-200',
    }),
    []
  );

  const router = useRouter();
  const currentPath = router?.asPath ?? '';

  const domain =
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:3000'
      : `https://${process.env.NEXT_PUBLIC_DOMAIN}`;

  const enableSearchEngineIndexing =
    process.env.NEXT_PUBLIC_ENABLE_SEARCH_ENGINE_INDEXING === 'true';

  const lowercaseDomain = domain ? domain.toLowerCase() : '';

  const contentImage = lowercaseDomain?.includes('ev.com')
    ? `${domain}/images/og/ev_com_main.png`
    : `${domain}/images/og/ev_ca_main.png`;

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.gtag = function gtag() {
      // eslint-disable-next-line prefer-rest-params
      window.dataLayer.push(arguments);
    };
  }, []);

  useEffect(() => {
    try {
      const ALLOWED_HOST_NAMES: ReadonlyArray<string> = [
        'ev.com',
        'ev.ca',
        'ev-demo.com',
        'ev-internal.com',
      ];
      if (ALLOWED_HOST_NAMES.includes(window.location.hostname)) {
        const guestRoleArn = process.env.NEXT_PUBLIC_RUM_GUEST_ROLE_ARN;
        const identityPoolId = process.env.NEXT_PUBLIC_RUM_IDENTITY_POOL_ID;
        const applicationId = process.env.NEXT_PUBLIC_RUM_APPLICATION_ID;
        if (guestRoleArn && identityPoolId && applicationId) {
          const config: AwsRumConfig = {
            sessionSampleRate: 1,
            guestRoleArn,
            identityPoolId,
            endpoint: 'https://dataplane.rum.us-east-1.amazonaws.com',
            telemetries: ['performance', 'errors', 'http'],
            allowCookies: true,
            enableXRay: false,
          };

          const APPLICATION_VERSION = '1.0.0';
          const APPLICATION_REGION = 'us-east-1';

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          window.rum = new AwsRum(
            applicationId,
            APPLICATION_VERSION,
            APPLICATION_REGION,
            config
          );
        }
      }
    } catch (error) {
      // Ignore errors thrown during CloudWatch RUM web client initialization
    }
  }, []);

  return (
    <>
      <Head>
        <link rel="canonical" href={`${domain}${currentPath}`} />
        <link rel="icon" href="/favicon.ico" />
        {!enableSearchEngineIndexing && (
          <meta name="robots" content="noindex" />
        )}

        <meta property="og:image" content={contentImage} key="og-image" />

        <meta
          property="og:title"
          content="Electric Cars for Sale"
          key="og-title"
        />
        <meta property="og:type" content="website" key="og-type" />
        <meta
          property="og:description"
          content="Buy New & Pre-Owned Electric Vehicles."
          key="og-description"
        />
        <meta
          name="twitter:title"
          content="Electric Cars for Sale"
          key="twitter-title"
        />

        <meta
          property="og:url"
          content={`https://${process.env.NEXT_PUBLIC_DOMAIN}${currentPath}`}
          key="og-url"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <>
        <ErrorBoundary>
          <QueryClientProvider client={queryClient}>
            <AuthProvider ssrUser={user}>
              <FullStoryContextProvider>
                <PreviousUrlProvider>
                  <AmplitudeContextProvider>
                    <AnalyticsProvider>
                      <CachedDataProvider>
                        <SavedVehiclesProvider>
                          <ZipProvider>
                            <GoogleMapsAPIProvider
                              apiKey={GOOGLE_API_KEY}
                              libraries={['places']}
                            >
                              <VehicleFilterSettingsProvider>
                                <div className="flex min-h-screen flex-col">
                                  <section className="flex flex-grow flex-col">
                                    {!noHeader && <HeaderTemplate />}
                                    {children}
                                    {!noFooter && <FooterTemplate />}
                                  </section>
                                </div>
                              </VehicleFilterSettingsProvider>
                            </GoogleMapsAPIProvider>
                          </ZipProvider>
                        </SavedVehiclesProvider>
                      </CachedDataProvider>
                    </AnalyticsProvider>
                  </AmplitudeContextProvider>
                </PreviousUrlProvider>
              </FullStoryContextProvider>
            </AuthProvider>
          </QueryClientProvider>
        </ErrorBoundary>
        <GoogleTagManager
          gaId={process.env.NEXT_PUBLIC_GTM_ID || ''}
          gAdsId={process.env.NEXT_PUBLIC_GADS_ID || ''}
        />
        <ToastContainer
          toastClassName={(context) => {
            const toastBgClassName =
              context &&
              context?.type &&
              (context.type === 'info' ||
                context.type === 'success' ||
                context.type === 'error')
                ? toastBackgroundClass[context.type]
                : toastBackgroundClass.info;
            return `${toastBgClassName} relative flex p-1 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer border-[1px] border-solid boxShadow-m`;
          }}
          bodyClassName={(context) => {
            const fontColor =
              context?.type === 'info' ? 'text-white' : 'text-black';
            return `text-body2Regular px-l py-m ${fontColor}`;
          }}
          closeButton={false}
          position="bottom-center"
          toastStyle={{ alignSelf: 'center', maxWidth: 340 }}
          className="flex flex-col"
          icon={false}
          autoClose={3000}
          hideProgressBar
        />
      </>
    </>
  );
};
