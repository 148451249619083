const Seats = (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2089 1.58682C10.0987 1.28145 8.1755 2.7493 7.91336 4.86535L7.56687 7.66232C7.42034 8.84511 7.8238 9.98006 8.57672 10.8044C7.25074 11.8058 6.51655 13.502 6.81826 15.2234L9.00155 27.6796C9.10341 28.2608 9.29198 28.8028 9.55168 29.2946C9.28635 29.824 9.13702 30.4215 9.13702 31.0539C9.13702 32.35 9.76422 33.4996 10.7317 34.2157C10.861 36.5779 12.8175 38.4535 15.212 38.4535H27.6811C30.7562 38.4535 33.2491 35.9607 33.2491 32.8856C33.2491 29.4274 30.1297 26.8056 26.7231 27.4007L19.5007 28.6622C20.048 27.385 20.1399 25.907 19.6201 24.4975L15.2666 12.6916C15.0186 12.0191 14.6207 11.4445 14.1224 10.9944C14.7018 10.4192 15.0995 9.64994 15.2087 8.76817L15.5552 5.97119C15.8174 3.85514 14.3192 1.89219 12.2089 1.58682ZM17.1873 31.3346C17.119 31.3759 17.0497 31.4159 16.9794 31.4545C16.7832 33.3942 15.1767 34.9174 13.2011 34.983C13.5727 35.7164 14.3336 36.2191 15.212 36.2191H27.6811C29.5222 36.2191 31.0147 34.7266 31.0147 32.8856C31.0147 30.8151 29.1471 29.2455 27.1075 29.6017L17.1873 31.3346ZM13.0683 27.1226C12.4184 27.1226 11.8053 27.2804 11.2652 27.5596C11.2351 27.4528 11.2102 27.3433 11.1905 27.2315L9.00726 14.7752C8.80288 13.6091 9.56061 12.4664 10.7149 12.2C11.7639 11.9578 12.8108 12.52 13.1812 13.5243L17.5347 25.3302C18.0494 26.7258 17.5604 28.2337 16.4928 29.1217C15.8179 27.9282 14.5372 27.1226 13.0683 27.1226ZM10.3876 5.22339C10.48 4.47758 11.1578 3.96023 11.9016 4.06786C12.6454 4.17549 13.1734 4.86734 13.081 5.61315L12.7345 8.41012C12.6421 9.15594 11.9643 9.67329 11.2205 9.56566C10.4767 9.45803 9.9487 8.76618 10.0411 8.02036L10.3876 5.22339ZM11.637 31.0539C11.637 30.2635 12.2778 29.6226 13.0683 29.6226C13.8588 29.6226 14.4996 30.2635 14.4996 31.0539C14.4996 31.8444 13.8588 32.4852 13.0683 32.4852C12.2778 32.4852 11.637 31.8444 11.637 31.0539Z"
      fill="#38393A"
    />
  </svg>
);

export default Seats;
