import Icon, { IconIds } from '@/components/atoms/legacy/Icon';
import { useAnalytics } from '@/components/molecules/AnalyticsProvider';
import { AnalyticsEvent } from '@/lib/handleActionTracking';
import { Component } from '@/types/interfaces';
import { useRouter } from 'next/compat/router';
import NextLink, { LinkProps as NextLinkProp } from 'next/link';
import React, { HTMLAttributeAnchorTarget } from 'react';

export enum LinkVariants {
  Primary = 'link-primary',
  Secondary = 'link-secondary',
  Tertiary = 'link-tertiary',
  Reverse = 'link-reverse',
  Default = 'link-default',
}

export enum LinkSizes {
  Normal = 'link-normal',
  Small = 'link-small',
}

export interface LinkProps extends Component, NextLinkProp {
  activeClassName?: string;
  iconClassName?: string;
  activeIconClassName?: string;
  size?: LinkSizes;
  variant?: LinkVariants;
  prependIcon?: IconIds | React.ReactElement;
  appendIcon?: IconIds | React.ReactElement;
  activeIconClass?: string;
  key?: string | number;
  childrenStyle?: string;
  directionIconColor?: string;
  target?: HTMLAttributeAnchorTarget | undefined;
  onClick?: (evt?: React.MouseEvent) => void;
  analyticsEvent?: AnalyticsEvent;
}

const colorMapping = {
  [LinkVariants.Primary]: '#28BE98',
  [LinkVariants.Secondary]: '#6A838E',
  [LinkVariants.Tertiary]: '#007BCC',
  [LinkVariants.Reverse]: '#EFF1F2',
  [LinkVariants.Default]: '#000',
};

const Link: React.FC<LinkProps> = ({
  className = 'w-full h-full',
  activeClassName = '',
  size = LinkSizes.Normal,
  variant = LinkVariants.Default,
  prependIcon,
  appendIcon,
  children,
  iconClassName = '',
  childrenStyle = 'w-full h-full',
  directionIconColor,
  onClick = () => {},
  analyticsEvent,
  ...props
}) => {
  const router = useRouter();
  const [computedClassName, setComputedClassName] = React.useState(className);

  React.useEffect(() => {
    // Check if the router fields are updated client-side
    if (router?.isReady) {
      // Dynamic route will be matched via props.as
      // Static route will be matched via props.href
      const linkPathname = new URL(
        (props.as || props.href) as string,
        window.location.href
      ).pathname;

      // Using URL().pathname to get rid of query and hash
      const activePathname = new URL(router.asPath, window.location.href)
        .pathname;

      const newClassName =
        linkPathname === activePathname
          ? `${className} ${activeClassName}`.trim()
          : className;

      if (newClassName !== computedClassName) {
        setComputedClassName(newClassName);
      }
    }
  }, [
    router,
    props.as,
    props.href,
    activeClassName,
    className,
    computedClassName,
  ]);
  const { handleActionTracking } = useAnalytics();

  return (
    <NextLink
      className={`link group flex justify-center ${size} ${variant} ${computedClassName}`}
      onClick={() => {
        if (analyticsEvent) {
          handleActionTracking(analyticsEvent);
        }
        onClick();
      }}
      {...props}
    >
      {prependIcon && typeof prependIcon !== 'object' && (
        <Icon
          iconId={prependIcon}
          strokeColor={directionIconColor || colorMapping[variant]}
          fillColor={directionIconColor || colorMapping[variant]}
          width={size === LinkSizes.Normal ? '14' : '9'}
          height={size === LinkSizes.Normal ? '14' : '9'}
        />
      )}

      {typeof prependIcon === 'object' && prependIcon}
      <div className={childrenStyle}>{children}</div>
      {appendIcon && typeof appendIcon !== 'object' && (
        <Icon
          className={iconClassName + activeClassName}
          iconId={appendIcon}
          strokeColor={directionIconColor || colorMapping[variant]}
          fillColor={directionIconColor || colorMapping[variant]}
          width={size === LinkSizes.Normal ? '14' : '9'}
          height={size === LinkSizes.Normal ? '14' : '9'}
        />
      )}
      {typeof appendIcon === 'object' && appendIcon}
    </NextLink>
  );
};

export default Link;
