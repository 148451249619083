import React from 'react';

const Message = (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.6668 9.33398V15.1673C25.6668 19.834 23.3335 22.1673 18.6668 22.1673H18.0835C17.7218 22.1673 17.3718 22.3423 17.1502 22.634L15.4002 24.9673C14.6302 25.994 13.3702 25.994 12.6002 24.9673L10.8502 22.634C10.6635 22.3773 10.2318 22.1673 9.91683 22.1673H9.3335C4.66683 22.1673 2.3335 21.0007 2.3335 15.1673V9.33398C2.3335 4.66732 4.66683 2.33398 9.3335 2.33398H14.0002"
      stroke="#9ECAFF"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.7334 5.3905C17.3484 4.2355 17.8034 2.81217 19.0634 2.4155C19.7167 2.21717 20.5334 2.3805 21 2.99883C21.4434 2.35717 22.2834 2.21717 22.9367 2.4155C24.1967 2.8005 24.6517 4.2355 24.2667 5.3905C23.6717 7.22217 21.5834 8.16717 21 8.16717C20.4167 8.16717 18.3517 7.23383 17.7334 5.3905Z"
      stroke="#9ECAFF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.6624 12.8333H18.6729"
      stroke="#9ECAFF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.9949 12.8333H14.0054"
      stroke="#9ECAFF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.32693 12.8333H9.3374"
      stroke="#9ECAFF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Message;
