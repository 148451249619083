const Lucid = (
  <svg
    width="33"
    height="33"
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.1721 19.9269H7.25876C5.02832 19.9269 4.03655 18.9234 4.03655 16.7684V11.9592H0.172119V16.7684C0.172119 19.8811 2.86966 21.5955 5.44408 21.5955H32.1721V19.9269Z"
      fill="#080812"
    />
  </svg>
);

export default Lucid;
