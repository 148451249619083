export const Contract = (
  <svg
    width="78"
    height="84"
    viewBox="0 0 78 84"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Frame 1000004009">
      <path
        id="Vector"
        d="M13.3977 9.72437H49.1537C54.8406 9.72437 59.4595 14.3433 59.4595 20.0303V47.3759H3.09888V20.0303C3.09888 14.3433 7.7178 9.72437 13.4048 9.72437H13.3977Z"
        fill="#DDDDDD"
      />
      <g id="Group">
        <path
          id="Vector_2"
          d="M21.546 36.2992C23.3352 36.2992 24.7856 34.8487 24.7856 33.0596C24.7856 31.2704 23.3352 29.8199 21.546 29.8199C19.7568 29.8199 18.3064 31.2704 18.3064 33.0596C18.3064 34.8487 19.7568 36.2992 21.546 36.2992Z"
          fill="#A6A6A6"
        />
        <path
          id="Vector_3"
          d="M41.1534 36.2992C42.9426 36.2992 44.393 34.8487 44.393 33.0596C44.393 31.2704 42.9426 29.8199 41.1534 29.8199C39.3642 29.8199 37.9138 31.2704 37.9138 33.0596C37.9138 34.8487 39.3642 36.2992 41.1534 36.2992Z"
          fill="#A6A6A6"
        />
        <path
          id="Vector_4"
          d="M15.2083 34.7713H46.6919C47.0526 34.7713 47.378 34.559 47.5124 34.2337C47.5124 34.2337 49.401 29.9048 48.9129 28.7023C48.722 28.5184 42.172 26.0003 39.7317 25.0383C37.2914 24.0763 34.7945 23.7439 32.5735 23.6873C29.7653 23.6166 27.0421 24.5644 24.9201 26.3398C24.2269 26.9198 23.8025 28.0728 22.9961 28.0728C21.1641 28.0728 17.7476 28.3133 16.3188 29.3813C15.8237 29.7492 14.7556 30.9163 14.6 31.5034C14.4373 32.14 14.1685 33.2151 14.3524 34.0993C14.4373 34.4954 14.791 34.7713 15.2083 34.7713Z"
          fill="#A6A6A6"
        />
      </g>
      <path
        id="Vector_5"
        d="M14.4093 57.908H49.0335"
        stroke="#2B2B2B"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_6"
        d="M3.53748 47.376H59.4525"
        stroke="#2B2B2B"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_7"
        d="M14.4093 67.6553H49.0335"
        stroke="#2B2B2B"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_8"
        d="M51.2757 9.39185H11.6577C6.86439 9.39185 2.97864 13.2776 2.97864 18.0709V73.321C2.97864 78.1143 6.86439 82 11.6577 82H51.2757C56.069 82 59.9547 78.1143 59.9547 73.321V18.0709C59.9547 13.2776 56.069 9.39185 51.2757 9.39185Z"
        stroke="#2B2B2B"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_9"
        d="M70.6355 59.6481L58.9644 52.5748L47.1873 59.6481V28.8011H70.6355V59.6481Z"
        fill="#32FFC8"
        stroke="#2B2B2B"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_10"
        d="M59.0637 33.9151C67.8768 33.9151 75.0213 26.7706 75.0213 17.9575C75.0213 9.14443 67.8768 2 59.0637 2C50.2506 2 43.1062 9.14443 43.1062 17.9575C43.1062 26.7706 50.2506 33.9151 59.0637 33.9151Z"
        fill="#32FFC8"
        stroke="#2B2B2B"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_11"
        d="M64.92 16.812L60.4567 16.7625L60.5345 9.59009L53.4116 19.9384L57.8749 19.988L57.8042 26.4601L64.9129 16.812H64.92Z"
        fill="#2B2B2B"
      />
    </g>
  </svg>
);
