const BatterySize = (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 6.5C0 4.29086 1.74718 2.5 3.90244 2.5H33.1707C35.326 2.5 37.0732 4.29086 37.0732 6.5V9.5H38.0488C39.1264 9.5 40 10.3954 40 11.5V14.5C40 15.6046 39.1264 16.5 38.0488 16.5H37.0732V19.5C37.0732 21.7091 35.326 23.5 33.1707 23.5H3.90244C1.74718 23.5 0 21.7091 0 19.5V6.5ZM3.90244 5H33.1707C33.979 5 34.6341 5.67157 34.6341 6.5V19.5C34.6341 20.3284 33.979 21 33.1707 21H3.90244C3.09422 21 2.43902 20.3284 2.43902 19.5V6.5C2.43902 5.67157 3.09422 5 3.90244 5Z"
      fill="#38393A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 26.5C0 25.9477 0.436795 25.5 0.97561 25.5H36.0976C36.6364 25.5 37.0732 25.9477 37.0732 26.5V36.5C37.0732 37.0523 36.6364 37.5 36.0976 37.5H0.97561C0.436796 37.5 0 37.0523 0 36.5V26.5ZM2.43902 35V28H6.82927V31.5H7.80488V28H10.7317V30.5H11.7073V28H14.6341V31.5H15.6098V28H18.5366V30.5H19.5122V28H22.439V31.5H23.4146V28H26.3415V30.5H27.3171V28H30.2439V31.5H31.2195V28H34.6341V35H2.43902Z"
      fill="#38393A"
    />
  </svg>
);

export default BatterySize;
