import { fetchAuthSession, fetchUserAttributes } from 'aws-amplify/auth';
import { parseCognitoUser, parseEVUser } from './parse';
import { EVUser, RawCognitoUser, RawEVUser } from './types';

export const getCognitoUser = async () => {
  try {
    const attributes = (await fetchUserAttributes()) as RawCognitoUser;

    return parseCognitoUser(attributes);
  } catch (err) {
    return null;
  }
};

export const getUserAuthHeader = async () => {
  try {
    const session = await fetchAuthSession();
    const token = session.tokens?.idToken;

    return `Bearer ${token}`;
  } catch (err) {
    return null;
  }
};

export const getEVUser = async () => {
  try {
    const authHeader = await getUserAuthHeader();

    if (!authHeader) {
      return null;
    }

    const response = await fetch(`/api/user/info/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: authHeader,
      },
    });

    if (!response.ok) {
      return null;
    }

    const attributes: RawEVUser = await response.json();
    const newEvUser = parseEVUser(attributes);
    return newEvUser;
  } catch (err) {
    return null;
  }
};

export const setEVUserAttributes = async (attributes: EVUser) => {
  const authHeader = await getUserAuthHeader();

  if (!authHeader) {
    throw new Error('could not fetch auth header');
  }

  const response = await fetch(`/api/user/info/`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: authHeader,
    },
    body: JSON.stringify({
      full_name: attributes.fullName,
      first_name: attributes.firstName,
      last_name: attributes.lastName,
      customer_email: attributes.customerEmail,
      ev_id: attributes.evId,
      phone: attributes.phone,
      flagged_for_deletion: attributes.flaggedForDeletion,
      profile: attributes.profile,
    }),
  });

  if (!response.ok) {
    throw new Error('unable to update ev user attributes');
  }
};
