const Performance = (
  <svg
    width="41"
    height="40"
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.5 20C39.5 29.9735 30.9935 38.0586 20.5 38.0586C10.0066 38.0586 1.50004 29.9735 1.50004 20C1.50004 10.0265 10.0066 1.94141 20.5 1.94141C30.9935 1.94141 39.5 10.0265 39.5 20ZM36.6137 22.6447C35.399 29.2223 29.7339 34.4789 22.4911 35.3018L24.8119 28.565C26.8063 27.8658 28.3202 26.1006 28.6248 23.9041L28.7996 22.6447H36.6137ZM36.6137 17.3556H29.4899C29.4617 15.3845 27.8517 13.7376 25.812 13.7376H15.1882C13.1485 13.7376 11.5385 15.3845 11.5103 17.3556H4.38633C5.71017 10.186 12.3213 4.58597 20.5 4.58597C28.6788 4.58597 35.2899 10.186 36.6137 17.3556ZM4.3864 22.6447H12.2006L12.3754 23.9041C12.68 26.1006 14.1939 27.8658 16.1882 28.565L18.5091 35.3018C11.2661 34.4789 5.60105 29.2224 4.3864 22.6447ZM20.9868 34.7968L23.0224 28.8881C22.9818 28.8889 22.9412 28.8893 22.9004 28.8893H18.0998C18.059 28.8893 18.0183 28.8889 17.9778 28.8881L20.0133 34.7968C20.1728 35.2596 20.8273 35.2596 20.9868 34.7968ZM18.9462 18.9403L20.0275 20.8132V21.7347C20.0275 21.8317 20.0718 21.9234 20.1479 21.9837L20.3027 22.1064C20.4183 22.198 20.5818 22.198 20.6974 22.1064L20.8522 21.9837C20.9283 21.9234 20.9726 21.8317 20.9726 21.7347V20.8131L22.0539 18.9403C22.1841 18.7148 22.0027 18.437 21.7439 18.4655L20.5348 18.5987C20.5117 18.6012 20.4884 18.6012 20.4652 18.5987L19.2561 18.4655C18.9974 18.437 18.8159 18.7148 18.9462 18.9403Z"
      fill="#38393A"
    />
  </svg>
);

export default Performance;
