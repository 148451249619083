export const CopyCheck = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="fi:copy-check" clipPath="url(#clip0_2094_32065)">
      <path
        id="Vector"
        d="M10 12.4999L11.6667 14.1666L15 10.8333"
        stroke="#636363"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M16.6665 6.66675H8.33317C7.4127 6.66675 6.6665 7.41294 6.6665 8.33341V16.6667C6.6665 17.5872 7.4127 18.3334 8.33317 18.3334H16.6665C17.587 18.3334 18.3332 17.5872 18.3332 16.6667V8.33341C18.3332 7.41294 17.587 6.66675 16.6665 6.66675Z"
        stroke="#636363"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_3"
        d="M3.33317 13.3334C2.4165 13.3334 1.6665 12.5834 1.6665 11.6667V3.33341C1.6665 2.41675 2.4165 1.66675 3.33317 1.66675H11.6665C12.5832 1.66675 13.3332 2.41675 13.3332 3.33341"
        stroke="#636363"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2094_32065">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
