export const Apple = (
  <svg
    width="20"
    height="22"
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.8418 17.1447C18.5091 17.9133 18.1153 18.6208 17.659 19.2713C17.0371 20.158 16.5278 20.7719 16.1354 21.1127C15.527 21.6722 14.8752 21.9587 14.1772 21.975C13.6761 21.975 13.0718 21.8324 12.3683 21.5432C11.6626 21.2553 11.014 21.1127 10.421 21.1127C9.79905 21.1127 9.13201 21.2553 8.41853 21.5432C7.70396 21.8324 7.12831 21.9832 6.68819 21.9981C6.01884 22.0266 5.35166 21.7319 4.68571 21.1127C4.26066 20.742 3.72901 20.1064 3.09212 19.2061C2.40878 18.2446 1.84698 17.1297 1.40686 15.8587C0.935507 14.4857 0.699219 13.1563 0.699219 11.8692C0.699219 10.3948 1.0178 9.12321 1.65592 8.0576C2.15742 7.20166 2.82459 6.52647 3.65962 6.03081C4.49464 5.53514 5.39688 5.28256 6.36852 5.2664C6.90017 5.2664 7.59736 5.43085 8.46375 5.75405C9.32769 6.07834 9.88243 6.24279 10.1256 6.24279C10.3075 6.24279 10.9237 6.0505 11.9684 5.66714C12.9564 5.31162 13.7902 5.16441 14.4732 5.2224C16.3241 5.37178 17.7147 6.10142 18.6395 7.41595C16.9841 8.41896 16.1653 9.82379 16.1816 11.626C16.1965 13.0297 16.7057 14.1979 17.7066 15.1254C18.1601 15.5558 18.6667 15.8885 19.2302 16.1248C19.108 16.4793 18.979 16.8188 18.8418 17.1447ZM14.5968 0.440125C14.5968 1.54038 14.1948 2.56768 13.3936 3.51854C12.4267 4.64892 11.2572 5.30211 9.98903 5.19904C9.97287 5.06705 9.9635 4.92812 9.9635 4.78214C9.9635 3.7259 10.4233 2.59552 11.2399 1.67127C11.6475 1.20331 12.166 0.814209 12.7948 0.503814C13.4221 0.19805 14.0156 0.0289566 14.5737 0C14.59 0.147086 14.5968 0.294182 14.5968 0.440111V0.440125Z"
      fill="white"
    />
  </svg>
);
export default Apple;
