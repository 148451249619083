import Wrapper from '@/components/atoms/legacy/Wrapper';
import { Footer } from '@/components/organisms/Footer';
import FunnelFooter from '@/components/organisms/FunnelFooter/FunnelFooter';
import { useRouter } from 'next/compat/router';
import React from 'react';
import styles from './FooterTemplate.module.scss';

export const FooterTemplate: React.FC = () => {
  const router = useRouter();
  const showFunnelFooter =
    !!router?.pathname &&
    (router.pathname.includes('/[...slug]') ||
      router.pathname.includes('/settings'));
  return (
    <Wrapper
      className={`bg-galaxyBlue relative bottom-0 w-full ${styles.footerWrapper}`}
    >
      {showFunnelFooter ? <FunnelFooter /> : <Footer id="main-footer" />}
    </Wrapper>
  );
};
