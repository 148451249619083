import React from 'react';
import { Component } from '@/types/interfaces';

const Wrapper: React.FC<Component> = ({ children, ...props }) => {
  return (
    <div {...props}>
      <div
        className={`mx-auto max-w-[1920px] px-[20px] m:px-[40px] l:px-[60px] xl:px-[100px]`}
      >
        {children}
      </div>
    </div>
  );
};

export default Wrapper;
