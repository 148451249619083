export const TwitterIcon = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="32"
    height="32"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Social icons" clipPath="url(#clip0_12927_4476)">
      <path
        id="Exclude"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.200195 20.0002C0.200195 9.06499 9.06499 0.200195 20.0002 0.200195C30.9354 0.200195 39.8002 9.06499 39.8002 20.0002C39.8002 30.9354 30.9354 39.8002 20.0002 39.8002C9.06499 39.8002 0.200195 30.9354 0.200195 20.0002ZM29.7013 9.16672L21.9746 18.3411H21.9742L30.3777 30.8334H24.1973L18.5384 22.4211L11.4535 30.8334H9.62238L17.7255 21.2127L9.62238 9.16672H15.8028L21.1613 17.1327L27.8703 9.16672H29.7013ZM18.6457 20.1193L19.4667 21.3188V21.3193L25.0587 29.4894H27.8712L21.0184 19.477L20.1974 18.2775L14.9256 10.5747H12.1132L18.6457 20.1193Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_12927_4476">
        <rect width="40" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
