const Filters = (
  <svg
    width="14"
    height="12"
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 2H13" stroke="#101010" strokeLinecap="round" />
    <path d="M1 6H13" stroke="#101010" strokeLinecap="round" />
    <path d="M1 10H13" stroke="#101010" strokeLinecap="round" />
    <circle cx="11.5" cy="2" r="1.5" fill="#101010" />
    <circle cx="3.5" cy="6" r="1.5" fill="#101010" />
    <circle cx="9.5" cy="10" r="1.5" fill="#101010" />
  </svg>
);

export default Filters;
