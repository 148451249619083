const Sun = (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.9998 21.5827C18.188 21.5827 21.5832 18.1875 21.5832 13.9993C21.5832 9.81119 18.188 6.41602 13.9998 6.41602C9.81168 6.41602 6.4165 9.81119 6.4165 13.9993C6.4165 18.1875 9.81168 21.5827 13.9998 21.5827Z"
      stroke="#9ECAFF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.3302 22.3306L22.1785 22.179M22.1785 5.82232L22.3302 5.67065L22.1785 5.82232ZM5.67016 22.3306L5.82183 22.179L5.67016 22.3306ZM14.0002 2.42732V2.33398V2.42732ZM14.0002 25.6673V25.574V25.6673ZM2.42683 14.0007H2.3335H2.42683ZM25.6668 14.0007H25.5735H25.6668ZM5.82183 5.82232L5.67016 5.67065L5.82183 5.82232Z"
      stroke="#9ECAFF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Sun;
