const DropdownArrow = (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.2797 6.46667L8.93306 10.8133C8.41973 11.3267 7.57973 11.3267 7.06639 10.8133L2.71973 6.46667"
      stroke="#666666"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DropdownArrow;
