const Gas = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="1 1 24 24"
    fill="none"
  >
    <circle cx="12.9297" cy="12.942" r="12" fill="#080812" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2003 7.86562C15.239 7.68519 15.4166 7.57026 15.5971 7.60893C16.7568 7.85742 17.3922 8.30071 17.7751 8.7793C17.9625 9.01359 18.0797 9.24443 18.1672 9.43456C18.1929 9.49042 18.2146 9.53903 18.2338 9.58181C18.2867 9.69998 18.3196 9.77358 18.3605 9.83197C18.4563 9.96883 18.51 10.1199 18.5073 10.2781C18.5046 10.4347 18.4471 10.5665 18.3755 10.668C18.3031 10.7705 18.2103 10.8515 18.1183 10.913C18.2334 12.896 18.4433 16.5662 18.4433 16.8038C18.4433 17.0217 18.3634 17.3215 18.1443 17.5713C17.9148 17.833 17.5558 18.0135 17.0539 18.0135C16.6067 18.0135 16.2739 17.8035 16.0505 17.5602C15.8326 17.3229 15.7062 17.0425 15.6534 16.8455C15.6458 16.8172 15.642 16.7881 15.642 16.7589V13.728C15.642 13.1397 15.3233 12.8231 15.1514 12.7151H14.1799C13.9953 12.7151 13.8457 12.5655 13.8457 12.3809C13.8457 12.1964 13.9953 12.0468 14.1799 12.0468H15.2351C15.2828 12.0468 15.33 12.057 15.3734 12.0768C15.7218 12.2351 16.3103 12.7702 16.3103 13.728V16.7105C16.3464 16.8208 16.4231 16.9779 16.5428 17.1082C16.6715 17.2485 16.8364 17.3453 17.0539 17.3453C17.3827 17.3453 17.5514 17.2339 17.6418 17.1307C17.7427 17.0158 17.775 16.8777 17.775 16.8038C17.775 16.5832 17.5517 12.6809 17.4388 10.7389C17.4304 10.5947 17.5157 10.4614 17.6502 10.4086C17.6799 10.3969 17.7181 10.3776 17.7543 10.3524C17.7912 10.3268 17.8159 10.3019 17.8294 10.2828C17.8357 10.2739 17.8381 10.2684 17.8389 10.2659C17.8379 10.2604 17.8331 10.244 17.813 10.2152C17.7318 10.0993 17.6628 9.94396 17.6032 9.8098C17.5882 9.77617 17.5738 9.74386 17.56 9.71384C17.4819 9.54405 17.3914 9.36937 17.2532 9.19674C16.985 8.86148 16.4977 8.4853 15.457 8.26233C15.2766 8.22366 15.1616 8.04605 15.2003 7.86562ZM17.8392 10.2681C17.8391 10.2681 17.8391 10.2676 17.8391 10.2667C17.8392 10.2677 17.8392 10.2681 17.8392 10.2681ZM17.8391 10.2653C17.8392 10.2647 17.8393 10.2644 17.8393 10.2644C17.8394 10.2644 17.8393 10.2646 17.8391 10.2653Z"
      fill="#F9FAFB"
    />
    <ellipse
      cx="1.02673"
      cy="0.477943"
      rx="1.02673"
      ry="0.477943"
      transform="matrix(0.856144 -0.516738 0.516816 0.856097 16.7285 9.62442)"
      fill="#F9FAFB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.61314 7.11043C7.61314 6.60321 8.02433 6.19202 8.53156 6.19202H13.5832C14.0904 6.19202 14.5016 6.6032 14.5016 7.11043V17.7634H7.61314V7.11043ZM8.4647 7.25716C8.4647 7.13035 8.5675 7.02756 8.69431 7.02756H13.4193C13.5461 7.02756 13.6489 7.13035 13.6489 7.25716V9.50715H8.4647V7.25716ZM7.10656 18.2227C6.97975 18.2227 6.87695 18.3254 6.87695 18.4523V19.692H15.235V18.4523C15.235 18.3254 15.1322 18.2227 15.0054 18.2227H7.10656Z"
      fill="#F9FAFB"
    />
  </svg>
);
export default Gas;
