export interface LogoURLs {
  [key: string]: string;
}

export const logoURLs: LogoURLs = {
  'EV.com': '/images/logo_us.svg',
  'EV.ca': '/images/logo.png',
};

export const logoURLsReverse: LogoURLs = {
  'EV.com': '/images/logo_us_reverse.svg',
  'EV.ca': '/images/logo_reverse.png',
};
