import {
  fetchAuthSession as fetchAuthSessionServer,
  fetchUserAttributes as fetchUserAttributesServer,
} from 'aws-amplify/auth/server';
import {
  GetServerSidePropsContext,
  NextApiRequest,
  NextApiResponse,
} from 'next';
import { createServerRunner } from '@aws-amplify/adapter-nextjs';
import { AUTH_CONFIG } from '@/lib/constants';
import { parseCognitoUser, parseEVUser } from './parse';
import { RawCognitoUser, RawEVUser } from './types';

interface NextApiContext {
  req: NextApiRequest;
  res: NextApiResponse;
}

export const { runWithAmplifyServerContext } = createServerRunner({
  config: AUTH_CONFIG,
});

export const getCognitoUserServerSide = async ({
  req,
  res,
}: GetServerSidePropsContext | NextApiContext) => {
  try {
    const attributes: RawCognitoUser = await runWithAmplifyServerContext({
      nextServerContext: { request: req, response: res },
      operation: (contextSpec) => fetchUserAttributesServer(contextSpec),
    });

    return parseCognitoUser(attributes);
  } catch (err) {
    return null;
  }
};

export const getUserAuthHeaderServerSide = async ({
  req,
  res,
}: GetServerSidePropsContext | NextApiContext) => {
  try {
    const session = await runWithAmplifyServerContext({
      nextServerContext: { request: req, response: res },
      operation: (contextSpec) => fetchAuthSessionServer(contextSpec),
    });
    const token = session.tokens?.idToken;

    return `Bearer ${token}`;
  } catch (err) {
    return null;
  }
};

export const getEVUserServerSide = async (
  context: GetServerSidePropsContext | NextApiContext
) => {
  try {
    const authHeader = await getUserAuthHeaderServerSide(context);

    if (!authHeader) {
      return null;
    }

    const response = await fetch(`${process.env.API_ENDPOINT}/api/user/info/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: authHeader,
      },
    });

    if (!response.ok) {
      return null;
    }

    const attributes: RawEVUser = await response.json();
    return parseEVUser(attributes);
  } catch (err) {
    return null;
  }
};
