const Volvo = (
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.4189 7.83899L26.8687 9.37484L26.8681 9.37524C28.1086 11.3297 28.8377 13.6424 28.8631 16.1274C28.9357 23.2315 23.2355 29.0493 16.1314 29.1219C9.02732 29.1945 3.20948 23.4943 3.1369 16.3902C3.06432 9.2861 8.76451 3.46826 15.8686 3.39571C18.4748 3.36907 20.9074 4.12013 22.9472 5.43167L24.5383 3.92203C22.0481 2.19132 19.0948 1.25879 16.0027 1.25879C15.951 1.25879 15.8987 1.259 15.8467 1.25959C11.8403 1.30052 8.08963 2.89917 5.28559 5.76106C2.48159 8.62301 0.959847 12.4055 1.00081 16.412C1.04143 20.3913 2.62174 24.1239 5.4506 26.9223C8.2774 29.7188 12.0229 31.2588 15.9973 31.2588C16.049 31.2588 16.1013 31.2585 16.1532 31.258C24.4239 31.1735 31.0838 24.3762 30.9993 16.1056C30.9687 13.1131 30.0671 10.2601 28.4189 7.83899Z"
      fill="#080812"
    />
    <path
      d="M30.4424 1.87174L23.6123 1.85106L23.5089 2.32708C23.5089 2.32708 25.1232 3.11359 25.5786 3.52751C26.0339 3.94146 26.1152 4.02031 26.1152 4.02031L23.9484 6.14417C24.7605 6.80173 25.4886 7.53963 26.144 8.34728L28.2071 6.19745L30.0285 8.63974H30.4424V1.87174Z"
      fill="#080812"
    />
    <path
      d="M8.46982 15.2013H7.94018V14.7939H9.77408V15.2017L9.1644 15.2013L7.94808 17.451L6.5152 17.4499L5.27774 15.2017H4.66876L4.66928 14.7939H7.33004L7.33019 15.2013L6.68647 15.2017L7.58412 16.8391L8.46982 15.2013Z"
      fill="#080812"
    />
    <path
      d="M16.9356 17.0385H16.3683V15.201H16.9801V14.7943L14.5236 14.7939L14.5239 15.201H15.1421L15.1429 17.0391H14.5236V17.451L18.2111 17.4507V16.2203H17.8058C17.8058 16.6914 17.4612 17.0385 16.9356 17.0385Z"
      fill="#080812"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0918 16.1129C10.0918 15.3493 10.7397 14.7408 11.9595 14.7408C13.1795 14.7408 13.8342 15.3493 13.8342 16.1129C13.8342 16.8871 13.217 17.5042 11.9665 17.5042C10.7156 17.5042 10.0918 16.8871 10.0918 16.1129ZM11.3132 16.0966C11.3047 16.5858 11.5005 17.0781 11.946 17.0862C12.4047 17.0946 12.6019 16.5972 12.6104 16.1194C12.6194 15.613 12.399 15.1628 11.9818 15.1555C11.5645 15.1482 11.3221 15.59 11.3132 16.0966Z"
      fill="#080812"
    />
    <path
      d="M21.3661 15.2013H21.8958L21.0101 16.8391L20.1125 15.2017L20.7562 15.2013L20.756 14.7939H18.0953L18.0947 15.2017H18.7037L19.9411 17.4499L21.374 17.451L22.5903 15.2013L23.2 15.2017V14.7939H21.3661V15.2013Z"
      fill="#080812"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.4957 16.112C23.4957 15.3422 24.1488 14.7286 25.3786 14.7286C26.6086 14.7286 27.2687 15.3422 27.2687 16.112C27.2687 16.8924 26.6465 17.5146 25.3857 17.5146C24.1246 17.5146 23.4957 16.8924 23.4957 16.112ZM24.7271 16.0956C24.7185 16.5888 24.9159 17.0851 25.365 17.0933C25.8276 17.1017 26.0263 16.6002 26.0348 16.1186C26.0439 15.608 25.8218 15.1541 25.4011 15.1467C24.9804 15.1394 24.736 15.5848 24.7271 16.0956Z"
      fill="#080812"
    />
  </svg>
);

export default Volvo;
