const Copy = (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_173_101689)">
      <path
        d="M5.46978 20.5485L3.40978 20.0485C2.89396 19.9251 2.44827 19.6019 2.17072 19.1499C1.89317 18.698 1.80651 18.1543 1.92978 17.6385L5.42978 3.04845C5.55315 2.53263 5.87635 2.08694 6.3283 1.80939C6.78025 1.53185 7.32394 1.44518 7.83978 1.56845L16.5898 3.66845C16.9731 3.75972 17.3208 3.96217 17.5895 4.25038C17.8581 4.53858 18.0356 4.89972 18.0998 5.28845"
        stroke="#343434"
      />
      <path
        d="M8.5 18.8584V22.3584C8.5 22.8888 8.71071 23.3975 9.08579 23.7726C9.46086 24.1477 9.96957 24.3584 10.5 24.3584H19.5C20.0304 24.3584 20.5391 24.1477 20.9142 23.7726C21.2893 23.3975 21.5 22.8888 21.5 22.3584V7.3584C21.5 6.82797 21.2893 6.31926 20.9142 5.94418C20.5391 5.56911 20.0304 5.3584 19.5 5.3584H10.5C9.96957 5.3584 9.46086 5.56911 9.08579 5.94418C8.71071 6.31926 8.5 6.82797 8.5 7.3584V9.8584M8.5 12.3584V16.3584M6.5 14.3584H10.5"
        stroke="#343434"
      />
    </g>
    <defs>
      <clipPath id="clip0_173_101689">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 0.858398)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Copy;
