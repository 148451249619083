const LinkedIn = (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_908_99589)">
      <path
        d="M24.0754 0H1.91953C0.858203 0 0 0.837891 0 1.87383V24.1211C0 25.157 0.858203 26 1.91953 26H24.0754C25.1367 26 26 25.157 26 24.1262V1.87383C26 0.837891 25.1367 0 24.0754 0ZM7.71367 22.1559H3.8543V9.74492H7.71367V22.1559ZM5.78398 8.05391C4.54492 8.05391 3.54453 7.05352 3.54453 5.81953C3.54453 4.58555 4.54492 3.58516 5.78398 3.58516C7.01797 3.58516 8.01836 4.58555 8.01836 5.81953C8.01836 7.04844 7.01797 8.05391 5.78398 8.05391ZM22.1559 22.1559H18.3016V16.123C18.3016 14.6859 18.2762 12.8324 16.2957 12.8324C14.2898 12.8324 13.9852 14.4016 13.9852 16.0215V22.1559H10.1359V9.74492H13.8328V11.441H13.8836C14.3965 10.466 15.6559 9.43516 17.5297 9.43516C21.4348 9.43516 22.1559 12.0047 22.1559 15.3461V22.1559Z"
        fill="#EDF6FA"
      />
    </g>
    <defs>
      <clipPath id="clip0_908_99589">
        <rect width="26" height="26" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default LinkedIn;
