export const screens = {
  s: 450,
  sm: 450,
  m: 744,
  md: 744,
  ml: 1020,
  l: 1440,
  lg: 1440,
  xl: 1920,
};

export const pxScreens = Object.fromEntries(
  Object.entries(screens).map(([key, size]) => [key, `${size}px`])
);
