import {
  TIKTOK_URL,
  INSTAGRAM_URL,
  FACEBOOK_URL,
  TWITTER_URL,
  LINKEDIN_URL,
} from '@/lib/constants';
import { FacebookIcon } from './FacebookIcon';
import { InstagramIcon } from './InstagramIcon';
import { LinkedInIcon } from './LinkedInIcon';
import { TictokIcon } from './TictokIcon';
import { TwitterIcon } from './TwitterIcon';

const footerIconGroup = [
  {
    href: TIKTOK_URL,
    icon: <TictokIcon className="hover:text-brandElectric" />,
    label: 'TikTok',
  },
  {
    href: INSTAGRAM_URL,
    icon: <InstagramIcon className="hover:text-brandElectric" />,
    label: 'Instagram',
  },
  {
    href: FACEBOOK_URL,
    icon: <FacebookIcon className="hover:text-brandElectric" />,
    label: 'Facebook',
  },
  {
    href: TWITTER_URL,
    icon: <TwitterIcon className="hover:text-brandElectric" />,
    label: 'Twitter',
  },
  {
    href: LINKEDIN_URL,
    icon: <LinkedInIcon className="hover:text-brandElectric" />,
    label: 'Linkedin',
  },
];

const FooterSocialIconGroup = () => {
  return (
    <>
      {footerIconGroup.map((footerIcon, index) => (
        <div key={index}>
          <a
            href={footerIcon.href}
            target="_blank"
            rel="noopener"
            aria-label={footerIcon.label}
          >
            {footerIcon.icon}
          </a>
        </div>
      ))}
    </>
  );
};

export default FooterSocialIconGroup;
