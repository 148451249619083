const Afeela = (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.16 18.7403H0L2.176 13.2603H3.144L5.36 18.7403H4.2L3.8 17.6683H1.552L1.16 18.7403ZM2.664 14.4763L1.84 16.7883H3.504L2.664 14.4763ZM9.8 13.2603H6.72V18.7403H7.824V16.3883H9.544V15.5003H7.824V14.1883H9.8V13.2603ZM15.032 13.2603H11.792V18.7403H15.032V17.8123H12.896V16.3883H14.784V15.5003H12.896V14.1883H15.032V13.2603ZM17.192 13.2603H20.432V14.1883H18.296V15.5003H20.184V16.3883H18.296V17.8123H20.432V18.7403H17.192V13.2603ZM23.704 13.2603H22.6L22.608 18.7403H25.408L25.784 17.8123H23.704V13.2603ZM26.64 18.7403H27.8L28.184 17.6683H30.432L30.832 18.7403H32L29.784 13.2603H28.816L26.64 18.7403ZM28.48 16.7883L29.304 14.4763L30.144 16.7883H28.48Z"
      fill="#080812"
    />
  </svg>
);

export default Afeela;
