const PlusBold = (
  <svg
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="+"
      d="M0.976074 7.1641V5.0041H13.0001V7.1641H0.976074ZM5.70407 11.7961V0.204102H8.22407V11.7961H5.70407Z"
      fill="#0EDAB5"
    />
  </svg>
);

export default PlusBold;
