import { showToast } from '@/lib/toast';
import React, { ReactNode } from 'react';

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch() {
    showToast('Something went wrong. Please try again later.', {
      type: 'error',
    });
    this.setState({ hasError: false });
  }

  render() {
    return this.props.children;
  }
}

export default ErrorBoundary;
