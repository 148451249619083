import { Address, useCustomZip, useUserLocation } from '@/hooks/useUserZip';
import { DEFAULT_US_ZIP_CODE } from '@/lib/constants';
import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
} from 'react';

interface UserZipType {
  userZip: string | undefined;
  userAddress: Address | undefined;
  loading: boolean;
  error: boolean;
}

interface CustomZipType {
  customZip: string | undefined;
  customAddress: string | undefined;
  setCustomZip: (postal: string, address: string | undefined) => void;
  clearCustomZip: () => void;
}

interface ZipContextValue extends UserZipType, CustomZipType {
  preferredZip: string;
}

const ZipContext = createContext<ZipContextValue | null>(null);

export const ZipProvider: FC<PropsWithChildren> = ({ children }) => {
  const {
    zip: userZip,
    address: userAddress,
    loading,
    error,
  } = useUserLocation();
  const {
    setCustomZip,
    clearCustomZip,
    zip: customZip,
    address: customAddress,
  } = useCustomZip();

  const preferredZip: string = useMemo(() => {
    if (customZip) {
      return customZip;
    }
    if (userZip && userAddress?.country === 'US') {
      return userZip;
    }
    return DEFAULT_US_ZIP_CODE;
  }, [userZip, customZip, userAddress]);

  const contextValue = useMemo(() => {
    return {
      userZip,
      userAddress,
      loading,
      error,
      customZip,
      customAddress,
      setCustomZip,
      clearCustomZip,
      preferredZip,
    };
  }, [
    userZip,
    userAddress,
    loading,
    error,
    customZip,
    customAddress,
    setCustomZip,
    clearCustomZip,
    preferredZip,
  ]);

  return (
    <ZipContext.Provider value={contextValue}>{children}</ZipContext.Provider>
  );
};

export const useZipContext = () => {
  const context = useContext(ZipContext);
  if (!context) {
    throw new Error('useZipContext must be used within a ZipProvider');
  }
  return context;
};
