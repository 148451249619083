const IncentiveMoneySignFull = (
  <svg
    width="327"
    height="327"
    viewBox="0 0 327 327"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_f_5_8)">
      <circle cx="163.141" cy="163.141" r="113.141" fill="#0EDAB5" />
    </g>
    <path
      d="M164.067 207.201C156.373 207.201 149.661 205.928 143.931 203.38C138.283 200.751 133.413 196.931 129.32 191.919L139.02 177.622C143.358 182.716 147.573 186.29 151.666 188.344C155.841 190.399 160.342 191.426 165.172 191.426C167.709 191.426 169.96 191.097 171.925 190.44C173.971 189.7 175.567 188.673 176.713 187.359C177.859 186.044 178.432 184.483 178.432 182.675C178.432 181.361 178.145 180.169 177.572 179.101C176.999 177.951 176.181 176.965 175.117 176.143C174.053 175.239 172.784 174.459 171.311 173.801C169.837 173.062 168.2 172.446 166.4 171.953C164.599 171.378 162.634 170.926 160.506 170.597C154.367 169.282 149.251 167.434 145.159 165.051C141.148 162.668 138.119 159.71 136.073 156.177C134.109 152.644 133.126 148.454 133.126 143.606C133.126 139.909 133.945 136.499 135.582 133.377C137.219 130.173 139.47 127.42 142.335 125.119C145.282 122.819 148.638 121.052 152.403 119.82C156.25 118.587 160.302 117.971 164.558 117.971C171.433 117.971 177.491 119.122 182.729 121.422C188.05 123.641 192.633 127.091 196.48 131.775L187.64 144.592C184.612 141.059 181.133 138.389 177.204 136.581C173.357 134.774 169.264 133.87 164.926 133.87C162.225 133.87 159.892 134.24 157.928 134.979C155.963 135.636 154.408 136.622 153.262 137.937C152.198 139.252 151.666 140.854 151.666 142.744C151.666 144.223 152.034 145.537 152.771 146.687C153.508 147.838 154.572 148.865 155.963 149.769C157.355 150.59 159.033 151.33 160.997 151.987C162.962 152.644 165.131 153.219 167.505 153.712C172.006 154.616 176.058 155.808 179.66 157.287C183.261 158.766 186.331 160.573 188.868 162.709C191.487 164.763 193.493 167.228 194.884 170.104C196.276 172.898 196.972 176.061 196.972 179.594C196.972 185.428 195.58 190.399 192.797 194.507C190.014 198.615 186.167 201.778 181.256 203.997C176.345 206.133 170.615 207.201 164.067 207.201ZM156.7 217.184L157.682 160.244L168.118 164.312L169.101 217.184H156.7ZM171.802 164.435L161.366 160.368L160.383 109.098H172.784L171.802 164.435Z"
      fill="white"
    />
    <circle
      cx="163.139"
      cy="163.141"
      r="96.4224"
      stroke="white"
      strokeWidth="3"
    />
    <defs>
      <filter
        id="filter0_f_5_8"
        x="0.233604"
        y="0.233604"
        width="325.814"
        height="325.814"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="24.8832"
          result="effect1_foregroundBlur_5_8"
        />
      </filter>
    </defs>
  </svg>
);

export default IncentiveMoneySignFull;
