const Cruise = (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.8044 13.1238C18.9773 13.1139 19.1434 13.0517 19.2818 12.9451C19.4202 12.8386 19.5246 12.6925 19.5818 12.5252C19.6389 12.358 19.6464 12.1771 19.6031 12.0056C19.5599 11.834 19.4679 11.6794 19.3387 11.5614C19.2096 11.4433 19.0491 11.3671 18.8777 11.3423C18.7062 11.3175 18.5314 11.3453 18.3754 11.4222C18.2193 11.4991 18.0891 11.6216 18.0011 11.7742C17.9131 11.9268 17.8714 12.1027 17.8811 12.2796C17.8872 12.3972 17.9159 12.5124 17.9657 12.6186C18.0155 12.7247 18.0853 12.8198 18.1711 12.8982C18.2569 12.9767 18.357 13.037 18.4657 13.0757C18.5744 13.1144 18.6895 13.1308 18.8044 13.1238Z"
      fill="#080812"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.9157 14.1489C28.0891 14.174 27.3046 14.5277 26.7287 15.1347C26.1527 15.7418 25.8305 16.5546 25.8305 17.4007C25.8305 18.2467 26.1527 19.0595 26.7287 19.6666C27.3046 20.2737 28.0891 20.6273 28.9157 20.6524C29.4389 20.6542 29.9548 20.5272 30.4199 20.282C30.8756 20.0605 31.2759 19.7354 31.5902 19.3316L30.6584 18.6598C30.4654 18.9251 30.2137 19.1398 29.924 19.2866C29.6343 19.4333 29.3147 19.5078 28.9915 19.5039C28.5171 19.5143 28.0555 19.3457 27.6949 19.0302C27.3509 18.7288 27.1174 18.3167 27.0326 17.8615H31.9943C32.1542 14.0599 28.9073 14.1575 28.9073 14.1575L28.9157 14.1489ZM27.0916 16.7905C27.5686 15.2113 28.9381 15.306 28.9381 15.306C29.3634 15.2995 29.7777 15.4438 30.1108 15.7144C30.4438 15.985 30.675 16.3653 30.7651 16.7905H27.0916Z"
      fill="#080812"
    />
    <path
      d="M4.08086 15.6735C3.80363 15.5234 3.4949 15.4446 3.18129 15.4438L3.1869 15.4352C2.85569 15.4351 2.53007 15.5226 2.24161 15.6891C1.95316 15.8556 1.71165 16.0956 1.5405 16.3857C1.36935 16.6759 1.27438 17.0063 1.26478 17.3451C1.25518 17.6838 1.33129 18.0193 1.48572 18.3191C1.64015 18.6189 1.86766 18.8728 2.14621 19.0562C2.42475 19.2395 2.74487 19.3461 3.07553 19.3657C3.4062 19.3852 3.7362 19.317 4.0335 19.1676C4.33083 19.0183 4.58536 18.7929 4.7725 18.5133L5.77156 19.2885C5.4777 19.7117 5.08936 20.0569 4.63886 20.2956C4.18833 20.5344 3.68863 20.6596 3.18129 20.661C2.63515 20.6613 2.09814 20.5176 1.62202 20.2439C1.14589 19.9702 0.746709 19.5756 0.46293 19.0982C0.179153 18.6208 0.0203507 18.0766 0.00182928 17.5181C-0.0166921 16.9597 0.105692 16.4057 0.357186 15.9097C0.60868 15.4137 0.980803 14.9923 1.4377 14.6862C1.89459 14.3801 2.42085 14.1996 2.96575 14.162C3.51066 14.1244 4.05583 14.231 4.54876 14.4717C5.04166 14.7123 5.4657 15.0787 5.78 15.5357L4.7725 16.3052C4.59543 16.0404 4.35806 15.8236 4.08086 15.6735Z"
      fill="#080812"
    />
    <path
      d="M15.6276 17.7322C15.6276 18.1533 15.4641 18.5572 15.173 18.855C14.882 19.1527 14.4872 19.32 14.0757 19.32C13.6641 19.32 13.2693 19.1527 12.9783 18.855C12.6872 18.5572 12.5237 18.1533 12.5237 17.7322V14.255H11.2665V17.7351C11.2665 18.4966 11.5621 19.2269 12.0884 19.7654C12.6147 20.3038 13.3286 20.6064 14.0728 20.6064C14.8171 20.6064 15.531 20.3038 16.0572 19.7654C16.5835 19.2269 16.8792 18.4966 16.8792 17.7351V14.255H15.622L15.6276 17.7322Z"
      fill="#080812"
    />
    <path d="M19.3879 14.255H18.1222V20.5432H19.3879V14.255Z" fill="#080812" />
    <path
      d="M23.8222 17.0518C24.0398 17.1406 24.2469 17.2543 24.4396 17.3906C24.6498 17.5441 24.8183 17.7499 24.9293 17.9885C25.0402 18.2271 25.09 18.4907 25.0738 18.7545C25.0716 19.1716 24.9319 19.5757 24.6772 19.9014C24.4225 20.2271 24.0679 20.4553 23.6706 20.549C23.1196 20.7041 22.5381 20.7061 21.9861 20.5547C21.4341 20.4033 20.9316 20.104 20.5303 19.6876L21.1758 18.7918C22.6042 19.9805 23.5556 19.3058 23.5556 19.3058C23.634 19.2529 23.6982 19.1809 23.7423 19.0961C23.7864 19.0113 23.8091 18.9166 23.8082 18.8205C23.8166 18.3496 23.202 18.1659 23.202 18.1659C23.0168 18.1027 22.8119 18.0395 22.5874 17.9792C22.1308 17.8666 21.693 17.6857 21.288 17.4423C21.0919 17.309 20.9317 17.1273 20.8222 16.914C20.6995 16.6649 20.6387 16.3888 20.6454 16.11C20.6373 15.7399 20.7356 15.3754 20.9282 15.062C21.1208 14.7486 21.3991 14.4999 21.7286 14.347C22.2422 14.1609 22.7937 14.1125 23.3307 14.2065C23.8677 14.3005 24.3722 14.5337 24.796 14.8839L24.1365 15.82C23.7458 15.5158 23.2692 15.3495 22.7782 15.3462C22.5529 15.331 22.3292 15.3948 22.144 15.5271C22.0774 15.5776 22.0234 15.6434 21.9864 15.7193C21.9493 15.7951 21.9303 15.8788 21.9307 15.9635C21.9307 15.9635 21.869 16.354 22.5341 16.6067C22.7137 16.6756 22.9185 16.7445 23.1431 16.8106C23.374 16.8768 23.6008 16.9573 23.8222 17.0518Z"
      fill="#080812"
    />
    <path
      d="M8.19905 14.2551V14.8868C8.74102 14.4386 9.40615 14.1746 10.1018 14.1317V15.5673C8.54422 15.5673 8.22992 16.5321 8.19905 17.003V20.5433H6.92215V14.2551H8.19905Z"
      fill="#080812"
    />
  </svg>
);

export default Cruise;
