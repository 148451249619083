const Apple = (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.4601 11.228C27.2856 11.363 24.206 13.0942 24.206 16.9436C24.206 21.396 28.1247 22.9712 28.242 23.0102C28.224 23.1062 27.6195 25.1674 26.1759 27.2676C24.8887 29.1158 23.5443 30.961 21.4992 30.961C19.4541 30.961 18.9278 29.7759 16.5669 29.7759C14.2662 29.7759 13.4482 31 11.5775 31C9.70687 31 8.40162 29.2898 6.90088 27.1896C5.16255 24.7234 3.75806 20.892 3.75806 17.2556C3.75806 11.423 7.55952 8.32973 11.3008 8.32973C13.2888 8.32973 14.9459 9.63186 16.194 9.63186C17.382 9.63186 19.2346 8.25172 21.4962 8.25172C22.3533 8.25172 25.433 8.32973 27.4601 11.228ZM20.4225 5.78248C21.3579 4.67537 22.0195 3.13921 22.0195 1.60306C22.0195 1.39004 22.0015 1.17402 21.9624 1C20.4406 1.05701 18.6301 2.0111 17.5384 3.27423C16.6812 4.24633 15.8812 5.78248 15.8812 7.33963C15.8812 7.57366 15.9203 7.80768 15.9384 7.88269C16.0346 7.90069 16.191 7.92169 16.3474 7.92169C17.7128 7.92169 19.4301 7.0096 20.4225 5.78248Z"
      fill="#080812"
    />
  </svg>
);

export default Apple;
