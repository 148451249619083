const TwitterGreen = (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24ZM33.15 17.25C34.2 17.1 35.1 16.95 36 16.5C35.4 17.55 34.5 18.45 33.45 19.05C33.75 26.1 28.65 33.75 19.5 33.75C16.8 33.75 14.25 32.85 12 31.5C14.55 31.8 17.25 31.05 19.05 29.7C16.8 29.7 15 28.2 14.4 26.25C15.15 26.4 15.9 26.25 16.65 26.1C14.4 25.5 12.75 23.4 12.75 21.15C13.5 21.45 14.25 21.75 15 21.75C12.9 20.25 12.15 17.4 13.5 15.15C16.05 18.15 19.65 20.1 23.7 20.25C22.95 17.25 25.35 14.25 28.5 14.25C29.85 14.25 31.2 14.85 32.1 15.75C33.3 15.45 34.35 15.15 35.25 14.55C34.95 15.75 34.2 16.65 33.15 17.25Z"
      fill="#0EDAB5"
    />
  </svg>
);

export default TwitterGreen;
