import { AuthContext } from '@/components/molecules/AuthProvider';
import { FullStory, init, isInitialized } from '@fullstory/browser';
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';

interface FullStoryContextType {
  trackEvent: (
    eventName: string,
    eventProperties: Record<string, unknown>
  ) => void;
}

export const FullStoryContext = createContext<FullStoryContextType | null>(
  null
);

export const FullStoryContextProvider = ({ children }: PropsWithChildren) => {
  const authContext = useContext(AuthContext);
  const user = authContext?.user;
  const enableFullStory = process.env.ENABLE_DEV !== 'true';

  const trackFullStoryEvent = useCallback(
    () => (eventName: string, eventProperties: Record<string, unknown>) => {
      if (enableFullStory) {
        FullStory('trackEvent', {
          name: eventName,
          properties: eventProperties,
        });
      }
    },
    [enableFullStory]
  );

  const contextValue = useMemo(
    () => ({ trackEvent: trackFullStoryEvent }),
    [trackFullStoryEvent]
  );

  useEffect(() => {
    const fullStoryInitialized = isInitialized();

    if (enableFullStory && !fullStoryInitialized) {
      init({
        orgId: process.env.NEXT_PUBLIC_FULL_STORY_ORG_ID as string,
      });
    }
  }, [enableFullStory]);

  useEffect(() => {
    if (user && enableFullStory) {
      FullStory('setIdentity', {
        uid: user?.email,
        properties: {
          evId: user?.email,
          email: user?.email,
          firstName: user?.firstName,
          lastName: user?.lastName,
          identityProvider: user?.identityProvider,
          phone: user?.phone,
        },
      });
    }
  }, [user, enableFullStory]);

  return (
    <FullStoryContext.Provider value={contextValue}>
      {children}
    </FullStoryContext.Provider>
  );
};
