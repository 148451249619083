const Range = (
  <svg
    width="41"
    height="40"
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Range" clipPath="url(#clip0_18361_106561)">
      <g id="Range Road">
        <path
          d="M35.0607 33.2522L24.7864 6.75514L26.5965 6.74792L40.5 33.2493L35.0607 33.2522Z"
          fill="#38393A"
        />
        <path
          d="M5.9393 33.2522L16.2136 6.75521L14.4035 6.748L0.5 33.2494L5.9393 33.2522Z"
          fill="#38393A"
        />
        <path
          d="M18.2046 28.7594C18.2601 28.1986 18.7319 27.7712 19.2955 27.7713L21.6982 27.7713C22.2612 27.7713 22.7326 28.1977 22.7889 28.7579L23.1193 32.0465C23.1842 32.692 22.6774 33.2523 22.0286 33.2523H18.9697C18.3215 33.2523 17.8149 32.693 17.8788 32.048L18.2046 28.7594Z"
          fill="#38393A"
        />
        <path
          d="M19.6294 19.6696C19.1871 19.6696 18.814 19.999 18.7592 20.4379L18.4307 23.0688C18.3653 23.5922 18.7734 24.0544 19.3009 24.0544H21.7004C22.2274 24.0544 22.6354 23.5929 22.5708 23.0699L22.2457 20.439C22.1914 19.9996 21.8181 19.6696 21.3754 19.6696H19.6294Z"
          fill="#38393A"
        />
        <path
          d="M19.3131 13.1248C19.3561 12.8593 19.5853 12.6642 19.8542 12.6642L21.1457 12.6642C21.4147 12.6642 21.6439 12.8593 21.6868 13.1248L22.0415 15.3172C22.0954 15.6505 21.838 15.9528 21.5004 15.9528H19.4996C19.1619 15.9528 18.9046 15.6505 18.9585 15.3172L19.3131 13.1248Z"
          fill="#38393A"
        />
        <path
          d="M20.1064 6.75492C20.0051 6.75492 19.917 6.82424 19.8933 6.92265L19.4692 8.67658C19.4359 8.81453 19.5404 8.94733 19.6824 8.94733H21.3167C21.4589 8.94733 21.5635 8.814 21.5296 8.67587L21.0994 6.92194C21.0754 6.82388 20.9875 6.75492 20.8865 6.75492H20.1064Z"
          fill="#38393A"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_18361_106561">
        <rect width="40" height="40" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default Range;
