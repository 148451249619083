import { getPublicIpAddress } from '@/lib/getPublicIpAddress';
import { useEffect, useState } from 'react';

interface BaseAddress {
  postal: string;
  city: string;
}
interface RawAddress extends BaseAddress {
  region_code: string;
  country_code: string;
}
export interface Address extends BaseAddress {
  state: string;
  country: string;
}

/**
 * The user's actual zip (based on ip location)
 */
export const useUserLocation = () => {
  const [address, setAddress] = useState<Address | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const sessionZip = sessionStorage.getItem('userAddress');
    if (sessionZip != null) {
      setAddress(JSON.parse(sessionZip));
      setLoading(false);
      return;
    }
    getPublicIpAddress()
      .then((ip) => {
        if (ip == null) {
          throw new Error('failed to fetch IP');
        }

        return fetch(`https://ipapi.co/${ip}/json/`);
      })
      .then<RawAddress>((res) => {
        if (!res.ok) {
          throw new Error('failed to fetch IP geodata');
        }
        return res.json();
      })
      .then((res) => {
        const newAddress: Address = {
          postal: res.postal,
          city: res.city,
          state: res.region_code,
          country: res.country_code,
        };
        sessionStorage.setItem('userAddress', JSON.stringify(newAddress));
        setLoading(false);
        setAddress(newAddress);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  }, []);

  return { zip: address?.postal, address, loading, error };
};

/**
 * The zip that the user has manually set to be used (assumed to be valid US zip)
 */
export const useCustomZip = () => {
  const [zip, setZip] = useState<string>();
  const [address, setAddress] = useState<string>();

  useEffect(() => {
    const postal = sessionStorage.getItem('postal');
    const customAddress = sessionStorage.getItem('address');
    if (postal) {
      setZip(postal);
    }
    if (customAddress) {
      setAddress(customAddress);
    }
  }, []);

  const setCustomZip = (postal: string, addr: string | undefined) => {
    sessionStorage.setItem('postal', postal);
    setZip(postal);

    if (addr) {
      sessionStorage.setItem('address', addr);
      setAddress(addr);
    } else {
      sessionStorage.removeItem('address');
      setAddress(undefined);
    }
  };

  const clearCustomZip = () => {
    sessionStorage.removeItem('postal');
    sessionStorage.removeItem('address');
    setZip(undefined);
    setAddress(undefined);
  };

  return {
    setCustomZip,
    clearCustomZip,
    zip,
    address,
  };
};
