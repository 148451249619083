const Driving = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.9129 1.5H7.08789C5.73789 1.5 5.4379 2.17501 5.2654 3.00751L4.65039 5.94749H13.3504L12.7354 3.00751C12.5629 2.17501 12.2629 1.5 10.9129 1.5Z"
      stroke="#7D97A3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.4299 10.74C14.4899 11.3775 13.9799 11.925 13.3274 11.925H12.3074C11.7224 11.925 11.6399 11.6775 11.5349 11.3625L11.4224 11.0399C11.2724 10.5974 11.1749 10.2974 10.3874 10.2974H7.60492C6.82492 10.2974 6.70491 10.6349 6.56991 11.0399L6.45742 11.3625C6.35242 11.67 6.26992 11.925 5.68492 11.925H4.66492C4.01242 11.925 3.50242 11.3775 3.56242 10.74L3.86992 7.42497C3.94492 6.60747 4.10241 5.93994 5.52741 5.93994H12.4649C13.8899 5.93994 14.0474 6.60747 14.1224 7.42497L14.4299 10.74Z"
      stroke="#7D97A3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.65004 4.3125H4.10254"
      stroke="#7D97A3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.8971 4.3125H13.3496"
      stroke="#7D97A3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.7373 8.12256H7.36481"
      stroke="#7D97A3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.6348 8.12256H12.2623"
      stroke="#7D97A3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 12.75V13.5"
      stroke="#7D97A3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 15.75V16.5"
      stroke="#7D97A3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.25 13.5L1.5 16.5"
      stroke="#7D97A3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.75 13.5L16.5 16.5"
      stroke="#7D97A3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Driving;
