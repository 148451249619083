const Oil = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="43"
    height="54"
    viewBox="0 0 43 54"
    fill="none"
  >
    <g clipPath="url(#clip0_24105_985)">
      <path
        d="M0 0H20.5391L21.4414 0.882812L21.5032 53.9317H0V0Z"
        fill="#EFEFF1"
      />
      <path
        d="M38.4003 42.8419L38.4016 42.8397C40.1631 39.8907 41.0745 36.7293 41.0745 33.3103C41.0745 30.9922 40.4104 28.3629 39.1503 25.4237C37.8971 22.5007 36.3888 19.7786 34.6224 17.2965L34.6024 17.2684L34.5811 17.2414C33.0314 15.2668 31.205 13.0906 29.1028 10.7472C26.9888 8.39074 23.3224 4.78454 22.4848 3.96067L22.4774 3.95335L21.4439 2.93683L20.4144 3.95737L15.9808 8.3523C13.7672 10.4606 11.6306 12.8791 9.60519 15.6402C7.5736 18.4097 5.79055 21.3902 4.29229 24.5811C2.70339 27.8166 1.92553 30.735 1.92553 33.3103C1.92553 36.7138 2.79422 39.9144 4.56783 42.8467C6.32379 45.7497 8.68804 48.1139 11.6862 49.8465C14.6827 51.578 17.9213 52.4681 21.4132 52.4681C24.9027 52.4681 28.1727 51.5793 31.2007 49.8516C34.173 48.1675 36.6542 45.7534 38.4003 42.8419Z"
        fill="#38393A"
        stroke="#EFEFF1"
        strokeWidth="2.93617"
      />
    </g>
    <defs>
      <clipPath id="clip0_24105_985">
        <rect width="43" height="54" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default Oil;
