export const Pen = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="vuesax/linear/edit-2">
      <g id="vuesax/linear/edit-2_2">
        <g id="edit-2">
          <path
            id="Vector"
            d="M7.73463 2.10118L2.94546 7.17035C2.76463 7.36285 2.58963 7.74202 2.55463 8.00452L2.3388 9.89452C2.26296 10.577 2.75296 11.0437 3.42963 10.927L5.30796 10.6062C5.57046 10.5595 5.93796 10.367 6.11879 10.1687L10.908 5.09952C11.7363 4.22452 12.1096 3.22702 10.8205 2.00785C9.53713 0.800351 8.56296 1.22618 7.73463 2.10118Z"
            stroke="#8E9192"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_2"
            d="M6.93555 2.94531C7.18638 4.55531 8.49305 5.78615 10.1147 5.94948"
            stroke="#8E9192"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_3"
            d="M1.75 12.832H12.25"
            stroke="#8E9192"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default Pen;
