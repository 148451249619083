const Lightning = (
  <svg
    width="36"
    height="40"
    viewBox="0 0 36 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5158 24.0496V36.4731L30.0625 16.2849H21.9345V3.47314L8.0007 24.0496H16.5158Z"
      stroke="#2AA78C"
      strokeWidth="2"
    />
  </svg>
);

export default Lightning;
