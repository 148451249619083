const FastCharger = (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.90244 9.5C1.74718 9.5 0 11.2909 0 13.5V26.5C0 28.7091 1.74718 30.5 3.90244 30.5H33.1707C35.326 30.5 37.0732 28.7091 37.0732 26.5V23.5H38.0488C39.1264 23.5 40 22.6046 40 21.5V18.5C40 17.3954 39.1264 16.5 38.0488 16.5H37.0732V13.5C37.0732 11.2909 35.326 9.5 33.1707 9.5H3.90244ZM33.1707 12H3.90244C3.09422 12 2.43902 12.6716 2.43902 13.5V26.5C2.43902 27.3284 3.09422 28 3.90244 28H33.1707C33.979 28 34.6341 27.3284 34.6341 26.5V13.5C34.6341 12.6716 33.979 12 33.1707 12ZM21.0103 19.1012L24.5 19.1354L18.9349 26L18.9897 21.3947L15.5 21.3606L21.0709 14L21.0103 19.1012Z"
      fill="#38393A"
    />
  </svg>
);

export default FastCharger;
