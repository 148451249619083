/**
 * Calls ip-api.com to get the public IP address of the user.
 * This API is free to use.
 * Read more: https://www.ipify.org/
 * @returns A public IP IPv4 address or undefined if the request fails.
 */
export const getPublicIpAddress = async (): Promise<string | undefined> => {
  try {
    const response = await fetch('https://api.ipify.org?format=json');
    if (!response.ok) {
      throw new Error('No IP address found');
    }
    const data: { ip: string } = await response.json();
    return data?.ip;
  } catch (_) {
    return undefined;
  }
};
