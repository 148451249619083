const HertzLogoPrimary = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="46"
    height="16"
    viewBox="0 0 46 16"
    fill="none"
  >
    <path
      d="M42.1149 16H0L0.483478 13.7231L42.6008 13.7219L42.1149 16Z"
      fill="#FFD100"
    />
    <path
      d="M32.0179 9.80768C31.6416 9.4028 31.8666 8.59661 32.0596 7.68681L32.7169 4.59661H35.4189L35.9083 2.29592H33.2039L33.6838 0H31.1367L29.4671 7.89759C29.2158 9.0777 29.1218 10.0006 29.6326 10.9949C30.109 11.919 31.7356 12.8014 33.7946 12.2441L34.2495 10.109C32.8538 10.2816 32.293 10.1006 32.0179 9.80768Z"
      fill="black"
    />
    <path
      d="M10.4448 0L9.22894 5.70408H4.75856L5.97082 0H3.39982L0.800235 12.2441H3.36886L4.26674 8.00119H8.7395L7.83924 12.2441H10.3876L12.9896 0H10.4448Z"
      fill="black"
    />
    <path
      d="M25.123 3.16046C24.231 3.90473 23.7249 4.88002 23.3963 6.41857L22.159 12.2429H24.7086L25.7362 7.41291C25.9792 6.28282 26.1649 5.57665 26.5889 5.142C27.1724 4.54539 28.0191 4.51205 29.0348 4.5966L29.5255 2.29591C27.7333 1.84102 26.1685 2.28758 25.123 3.16046Z"
      fill="black"
    />
    <path
      d="M45.0229 2.29593H37.0646L36.5776 4.59661H41.1944L35.4201 10.0316L34.9509 12.2441H42.908L43.3986 9.94463H38.7556L44.5585 4.48467L45.0229 2.29593Z"
      fill="black"
    />
    <path
      d="M18.1649 2.11372C17.304 2.11372 16.1262 2.38047 14.9687 3.28907C12.3596 5.33611 11.064 12.4251 16.6669 12.4251C18.1197 12.4251 19.7356 11.9667 20.8562 11.12L19.8428 9.40756C18.9949 10.0601 14.7234 11.4784 15.1736 8.01667H21.8505C21.8529 8.00953 22.702 5.24204 21.4778 3.56773C20.4358 2.13873 18.515 2.11372 18.1649 2.11372ZM19.8226 5.9089H15.7356C15.8797 5.35874 16.7478 4.00476 18.359 4.15243C20.0929 4.31319 19.8226 5.9089 19.8226 5.9089Z"
      fill="black"
    />
  </svg>
);

export default HertzLogoPrimary;
