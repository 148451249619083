export const LinkedInIcon = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="32"
    height="32"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Social icons" clipPath="url(#clip0_12927_4480)">
      <path
        id="Vector"
        d="M21.1984 16.7145V16.6729C21.1904 16.6865 21.1784 16.7017 21.1704 16.7145H21.1984Z"
        fill="currentColor"
      />
      <path
        id="Vector_2"
        d="M20.0002 0.226562C9.06499 0.226562 0.200195 9.09136 0.200195 20.0266C0.200195 30.9618 9.06499 39.8266 20.0002 39.8266C30.9354 39.8266 39.8002 30.9618 39.8002 20.0266C39.8002 9.09136 30.9354 0.226562 20.0002 0.226562ZM14.5474 27.7274H10.2658V14.8474H14.5474V27.7274ZM12.4074 13.0898H12.3786C10.9418 13.0898 10.0114 12.1002 10.0114 10.8634C10.0114 9.62656 10.9698 8.63856 12.4346 8.63856C13.8994 8.63856 14.801 9.59936 14.829 10.8634C14.829 12.1002 13.9002 13.0898 12.4066 13.0898H12.4074ZM29.989 27.7274H25.7074V20.8362C25.7074 19.105 25.0874 17.9234 23.5378 17.9234C22.3546 17.9234 21.6506 18.7194 21.3402 19.4898C21.2274 19.765 21.1986 20.1482 21.1986 20.5338V27.7274H16.9162C16.9162 27.7274 16.973 16.0562 16.9162 14.8474H21.1978V16.673C21.7666 15.797 22.7826 14.545 25.057 14.545C27.8754 14.545 29.9874 16.3858 29.9882 20.3418V27.7282L29.989 27.7274Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_12927_4480">
        <rect width="40" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
