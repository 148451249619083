const TicToc = (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.3407 0H16.2848V20.4347C16.2848 22.8696 14.3402 24.8696 11.9203 24.8696C9.50042 24.8696 7.55586 22.8696 7.55586 20.4347C7.55586 18.0435 9.45721 16.0869 11.7907 16V10.8696C6.6484 10.9565 2.5 15.1739 2.5 20.4347C2.5 25.7392 6.73482 30 11.9636 30C17.1922 30 21.427 25.6957 21.427 20.4347V9.9565C23.3284 11.3478 25.6619 12.1739 28.125 12.2174V7.08696C24.3223 6.95652 21.3407 3.82608 21.3407 0Z"
      fill="#EDF6FA"
    />
  </svg>
);

export default TicToc;
