import Image, { ImageProps } from '@/components/atoms/Image';
import React from 'react';
import { logoURLs, logoURLsReverse } from './logoURL';

export interface LogoProps extends Omit<ImageProps, 'alt' | 'src'> {
  reverse?: boolean;
}

const domain: string = process.env.NEXT_PUBLIC_DOMAIN ?? 'EV.com';

const Logo: React.FC<LogoProps> = ({
  reverse = false,
  width = 104,
  height = 28,
}) => {
  return (
    <Image
      className="relative flex-none object-contain"
      src={reverse ? logoURLsReverse[domain] : logoURLs[domain]}
      alt="ev.com"
      width={width}
      height={height}
      style={{ maxHeight: `${height}px`, maxWidth: `${width}px` }}
      disableLoadingGif
    />
  );
};

export default Logo;
