import Link from '@/components/atoms/Link';
import {
  BUILD_AND_PRICE,
  COMPARE_EV,
  GOOGLE_TRACK_INFO,
  NEWS,
  PARTNER,
  SEARCH,
} from '@/lib/constants';
import { AnalyticsEvent } from '@/lib/handleActionTracking';

interface DesktopLinks {
  text: string;
  href: string;
  condition: boolean;
  analyticsEvent?: AnalyticsEvent;
}

const desktopLinks: DesktopLinks[] = [
  {
    text: SEARCH,
    href: '/search',
    condition: true,
    analyticsEvent: {
      ...GOOGLE_TRACK_INFO.inventoryHomePageButton,
      pageTarget: '/search',
    },
  },
  {
    text: BUILD_AND_PRICE,
    href: '/build',
    condition: process.env.NEXT_PUBLIC_BUILD_AND_PRICE_ENABLED === 'true',
    analyticsEvent: {
      ...GOOGLE_TRACK_INFO.customOrderHomePageButton,
      pageTarget: '/build',
    },
  },

  {
    text: COMPARE_EV,
    href: '/compare',
    condition: true,
    analyticsEvent: {
      ...GOOGLE_TRACK_INFO.compareEVHomePageButton,
      pageTarget: '/compare',
    },
  },
  {
    text: NEWS,
    href: '/news',
    condition: true,
    analyticsEvent: {
      ...GOOGLE_TRACK_INFO.newsHomePageButton,
      pageTarget: '/news',
    },
  },
  {
    text: PARTNER,
    href: '/partner',
    condition: process.env.NEXT_PUBLIC_ENABLE_PARTNER_PAGE === 'true',
    analyticsEvent: {
      ...GOOGLE_TRACK_INFO.partnerHomePageButton,
      pageTarget: '/partner',
    },
  },
];

export const DesktopHeaderLinks = () => {
  return (
    <>
      {desktopLinks.map((link, index) => {
        if (link.condition) {
          return (
            <Link
              className="border-b-2 border-white py-s text-body1Regular text-neutral-800 hover:border-b-brandElectric/80 hover:text-brandMidnight/80"
              activeClassName="!text-body1Medium !text-brandMidnight !border-b-brandElectric"
              href={link.href}
              analyticsEvent={link.analyticsEvent}
              key={index}
            >
              {link.text}
            </Link>
          );
        }
        return null;
      })}
    </>
  );
};
