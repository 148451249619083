const Share = (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.2676 10.2834C31.6009 12.6001 33.9009 16.2834 34.3676 20.5334"
      stroke="#007BCC"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.81641 20.6167C6.24974 16.3834 8.51641 12.7 11.8164 10.3667"
      stroke="#007BCC"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.6504 34.8999C15.5837 35.8832 17.7837 36.4332 20.1004 36.4332C22.3337 36.4332 24.4337 35.9332 26.3171 35.0166"
      stroke="#007BCC"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.1001 12.8333C22.6591 12.8333 24.7335 10.7589 24.7335 8.19998C24.7335 5.64106 22.6591 3.56665 20.1001 3.56665C17.5412 3.56665 15.4668 5.64106 15.4668 8.19998C15.4668 10.7589 17.5412 12.8333 20.1001 12.8333Z"
      stroke="#007BCC"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.04935 33.2C10.6083 33.2 12.6827 31.1256 12.6827 28.5667C12.6827 26.0078 10.6083 23.9333 8.04935 23.9333C5.49043 23.9333 3.41602 26.0078 3.41602 28.5667C3.41602 31.1256 5.49043 33.2 8.04935 33.2Z"
      stroke="#007BCC"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.9497 33.2C34.5087 33.2 36.5831 31.1256 36.5831 28.5667C36.5831 26.0078 34.5087 23.9333 31.9497 23.9333C29.3908 23.9333 27.3164 26.0078 27.3164 28.5667C27.3164 31.1256 29.3908 33.2 31.9497 33.2Z"
      stroke="#007BCC"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Share;
