import { CachedDataType, useCachedData } from '@/hooks/useCachedData';
import { FC, PropsWithChildren, createContext, useContext } from 'react';

interface CachedDataContextType {
  cachedData: CachedDataType | null;
  setCustomData: (data: CachedDataType | null) => void;
  clearCustomData: () => void;
}

const CachedDataContext = createContext<CachedDataContextType | undefined>(
  undefined
);

export const CachedDataProvider: FC<PropsWithChildren> = ({ children }) => {
  const { cachedData, setCustomData, clearCustomData } = useCachedData();

  return (
    <CachedDataContext.Provider
      value={{ cachedData, setCustomData, clearCustomData }}
    >
      {children}
    </CachedDataContext.Provider>
  );
};

export const useCachedDataContext = () => {
  const context = useContext(CachedDataContext);
  if (!context) {
    throw new Error('useCachedData must be used within a CachedDataProvider');
  }
  return context;
};
