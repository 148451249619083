const QueueList = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.5 5C2.5 3.61929 3.61929 2.5 5 2.5H15C16.3807 2.5 17.5 3.61929 17.5 5C17.5 6.38071 16.3807 7.5 15 7.5H5C3.61929 7.5 2.5 6.38071 2.5 5Z"
      fill="white"
    />
    <path
      d="M2.5 11.5625C2.5 11.0447 2.91973 10.625 3.4375 10.625H16.5625C17.0803 10.625 17.5 11.0447 17.5 11.5625C17.5 12.0803 17.0803 12.5 16.5625 12.5H3.4375C2.91973 12.5 2.5 12.0803 2.5 11.5625Z"
      fill="white"
    />
    <path
      d="M3.4375 15.625C2.91973 15.625 2.5 16.0447 2.5 16.5625C2.5 17.0803 2.91973 17.5 3.4375 17.5H16.5625C17.0803 17.5 17.5 17.0803 17.5 16.5625C17.5 16.0447 17.0803 15.625 16.5625 15.625H3.4375Z"
      fill="white"
    />
  </svg>
);

export default QueueList;
