import { FC } from 'react';
import Script from 'next/script';

export const GoogleTagManager: FC<{ gaId: string; gAdsId: string }> = ({
  gaId,
  gAdsId,
}) => {
  if (!gaId) return null;
  return (
    <>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${gaId}`}
        strategy="afterInteractive"
        onLoad={() => {
          window.gtag('js', new Date());
          window.gtag('config', gaId);
          if (gAdsId) {
            window.gtag('config', gAdsId);
          }
        }}
      />
    </>
  );
};
