import Button, { ButtonVariants } from '@/components/atoms/Button/Button';
import {
  ChatBubbleBottomCenterTextIcon,
  EnvelopeIcon,
} from '@heroicons/react/24/solid';
import { NextRouter } from 'next/router';

interface MobileFooterLinksProps {
  router: NextRouter;
}

export const MobileFooterLinks: React.FC<MobileFooterLinksProps> = ({
  router,
}) => {
  return (
    <div className="flex w-full flex-col gap-l bg-gradient-to-r from-blue-950 via-slate-900 to-slate-900 px-l pb-[220px] pt-l m:px-[40px]">
      <div className="text-body1Regular text-white">Contact us</div>
      <div className="mb-l flex flex-col gap-l">
        <Button
          aria-label="Email hi@ev.com"
          variant={ButtonVariants.Secondary}
          icon={{
            icon: <EnvelopeIcon className="h-xl w-xl" />,
            position: 'left',
          }}
          onClick={() => router.push('mailto:hi@ev.com')}
          theme="dark"
        >
          Email hi@ev.com
        </Button>
        <Button
          aria-label="Send us feedback"
          variant={ButtonVariants.Secondary}
          theme="dark"
          icon={{
            icon: <ChatBubbleBottomCenterTextIcon className="h-xl w-xl" />,
            position: 'left',
          }}
          onClick={() =>
            window.open(
              'https://my8by23bgby.typeform.com/to/Gg3XiMYY',
              '_blank'
            )
          }
        >
          Send us feedback
        </Button>
      </div>
    </div>
  );
};
