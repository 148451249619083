import { screens } from '@/styles/screens';
import { useEffect, useState } from 'react';

const { s, m, ml, l } = screens;

export default function useWindowSize() {
  const [isDesktop, setIsDesktop] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isML, setIsML] = useState(false);
  const [isLessThanML, setIsLessThanML] = useState(false);
  const [isM, setIsM] = useState(false);
  const [isS, setIsS] = useState(false);
  const [isXS, setIsXS] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isPortrait, setIsPortrait] = useState(false);
  const [hasScreenSizeChanged, setHasScreenSizeChanged] = useState(false);

  useEffect(() => {
    const handleOrientationChange = () => {
      // Check if orientation is supported
      const orientation = window.screen.orientation?.type;
      if (orientation) {
        setIsPortrait(orientation.includes('portrait'));
      }
    };

    const handleResize = () => {
      const newIsDesktop = window.innerWidth >= l;
      const newIsMobile = window.innerWidth < m;
      const newIsTablet = window.innerWidth >= m && window.innerWidth < l;
      const newIsML = window.innerWidth >= ml && window.innerWidth <= l;
      const newIsM = window.innerWidth >= m && window.innerWidth <= ml;
      const newIsS = window.innerWidth <= m && window.innerWidth >= s;
      const newIsXS = window.innerWidth < s;
      const newIsLessThanML = window.innerWidth < ml;

      if (
        newIsDesktop !== isDesktop ||
        newIsMobile !== isMobile ||
        newIsTablet !== isTablet ||
        newIsML !== isML ||
        newIsM !== isM ||
        newIsS !== isS ||
        newIsXS !== isXS ||
        newIsLessThanML !== isLessThanML
      ) {
        setHasScreenSizeChanged(true);
      } else {
        setHasScreenSizeChanged(false);
      }
      setIsML(newIsML);
      setIsLessThanML(newIsLessThanML);
      setIsM(newIsM);
      setIsS(newIsS);
      setIsXS(newIsXS);

      setIsDesktop(newIsDesktop);
      setIsMobile(newIsMobile);
      setIsTablet(newIsTablet);
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleOrientationChange);
    handleResize();
    handleOrientationChange();

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleOrientationChange);
    };
  }, [
    isDesktop,
    isMobile,
    isTablet,
    isML,
    isPortrait,
    isM,
    isS,
    isXS,
    isLessThanML,
  ]);

  return {
    isDesktop,
    isMobile,
    isTablet,
    isML,
    isLessThanML,
    isM,
    isS,
    isXS,
    isPortrait,
    hasScreenSizeChanged,
  };
}
