const Twitter = (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.4377 27.1877C20.7557 27.1877 26.9479 17.8086 26.9479 9.67751C26.9479 9.41384 26.942 9.14431 26.9303 8.88063C28.1348 8.00951 29.1744 6.9305 30 5.69431C28.8782 6.19343 27.6871 6.51941 26.4674 6.6611C27.7516 5.89132 28.7132 4.68202 29.1738 3.25739C27.9657 3.97338 26.6445 4.47845 25.2668 4.75095C24.3385 3.76463 23.1112 3.11158 21.7746 2.89275C20.438 2.67392 19.0665 2.9015 17.8722 3.5403C16.6779 4.17911 15.7273 5.19357 15.1673 6.42682C14.6074 7.66008 14.4693 9.04345 14.7744 10.3631C12.3281 10.2403 9.93492 9.60481 7.74998 8.49781C5.56503 7.3908 3.63712 5.83698 2.09121 3.93708C1.3055 5.29174 1.06507 6.89474 1.41879 8.4203C1.77251 9.94585 2.69383 11.2795 3.99551 12.1502C3.01829 12.1191 2.06247 11.856 1.20703 11.3826V11.4588C1.20616 12.8804 1.69762 14.2584 2.59789 15.3586C3.49815 16.4589 4.75165 17.2134 6.14531 17.4939C5.24007 17.7416 4.28998 17.7777 3.36855 17.5994C3.76182 18.822 4.52697 19.8913 5.55721 20.6581C6.58746 21.4249 7.8314 21.8509 9.11543 21.8767C6.93553 23.5891 4.24272 24.5178 1.4707 24.5134C0.979109 24.5127 0.487999 24.4826 0 24.4232C2.81607 26.2299 6.09191 27.1894 9.4377 27.1877Z"
      fill="#EDF6FA"
    />
  </svg>
);

export default Twitter;
