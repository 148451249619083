import { IFrameEntryPoint } from '@/components/entry-points/IFrameEntryPoint';
import { MarketPlaceEntryPoint } from '@/components/entry-points/MarketPlaceEntryPoint';
import '@/styles/globals.css';
import { BasePageProps, Page } from '@/types/page';
import { appWithTranslation } from 'next-i18next';
import type { AppProps } from 'next/app';
import { Lexend_Deca } from 'next/font/google';

interface CustomAppProps<T> extends Omit<AppProps, 'pageProps'> {
  Component: Page<BasePageProps>;
  pageProps: BasePageProps<T>;
}

const lexendDeca = Lexend_Deca({ subsets: ['latin'] });

function App<T>({
  Component,
  pageProps: { noHeader = false, noFooter = false, user, ...restPageProps },
}: CustomAppProps<T>) {
  const shellComponent = Component.isIframe ? (
    <IFrameEntryPoint>
      <Component {...restPageProps} />
    </IFrameEntryPoint>
  ) : (
    <MarketPlaceEntryPoint noHeader={noHeader} noFooter={noFooter} user={user}>
      <Component {...restPageProps} />
    </MarketPlaceEntryPoint>
  );

  return (
    <>
      <style jsx global>{`
        body {
          font-family: ${lexendDeca.style.fontFamily};
        }
      `}</style>
      {shellComponent}
    </>
  );
}

export default appWithTranslation(App);
