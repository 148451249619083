const TicTocGreen = (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0ZM28.6773 12C28.6773 12.3566 28.7107 12.7079 28.772 13.0478C29.0672 14.6191 29.9971 15.9675 31.2888 16.8089C32.1908 17.3996 33.2597 17.7395 34.4122 17.7395L34.412 18.6589V21.863C32.274 21.863 30.292 21.1776 28.6772 20.0185V28.4049C28.6772 32.5897 25.2699 36 21.0887 36C19.474 36 17.9707 35.4872 16.7402 34.6235C14.7805 33.2471 13.5 30.9737 13.5 28.4049C13.5 24.2145 16.9018 20.8099 21.0831 20.8153C21.4339 20.8153 21.7735 20.8433 22.1076 20.8879V21.863L22.0953 21.8688L22.1075 21.8685V25.1005C21.7846 25.0002 21.4394 24.9389 21.0831 24.9389C19.1734 24.9389 17.62 26.4936 17.62 28.4049C17.62 29.7367 18.3772 30.8901 19.4796 31.4753C19.4899 31.4894 19.5003 31.5034 19.5108 31.5173L19.5293 31.5417C19.5166 31.5172 19.502 31.4932 19.4852 31.4697C19.9696 31.726 20.5152 31.8709 21.0943 31.8709C22.9593 31.8709 24.485 30.3831 24.5517 28.5331L24.5573 12H28.6773Z"
      fill="#0EDAB5"
    />
  </svg>
);

export default TicTocGreen;
