import { useAuth } from '@/hooks/useAuth';
import { useUserLocation } from '@/hooks/useUserZip';
import {
  AnalyticsEvent,
  handleActionTracking,
} from '@/lib/handleActionTracking';
import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from 'react';

export const AnalyticsContext = createContext<{
  handleActionTracking: (event: AnalyticsEvent) => void;
}>({ handleActionTracking: () => {} });

export const useAnalytics = () => {
  const context = useContext(AnalyticsContext);
  if (!context) {
    throw new Error('useAnalytics must be used within an AnalyticsProvider');
  }
  return context;
};

export const AnalyticsProvider: FC<PropsWithChildren> = ({ children }) => {
  const { user } = useAuth();
  const { address } = useUserLocation();

  const memoizedHandleActionTracking = useCallback(
    (event: AnalyticsEvent) => {
      handleActionTracking({ username: user?.email, zip: address, ...event });
    },
    [user, address]
  );

  const value = useMemo(
    () => ({
      handleActionTracking: memoizedHandleActionTracking,
    }),
    [memoizedHandleActionTracking]
  );

  return (
    <AnalyticsContext.Provider value={value}>
      {children}
    </AnalyticsContext.Provider>
  );
};
