const Volkswagen = (
  <svg
    width="33"
    height="33"
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.8111 31.2588C25.1146 31.2588 31.8111 24.5624 31.8111 16.2587C31.8111 7.95521 25.1146 1.25879 16.8111 1.25879C8.50746 1.25879 1.81104 7.95521 1.81104 16.2587C1.81104 24.5624 8.50746 31.2588 16.8111 31.2588ZM12.4719 22.4731C12.3646 22.4731 12.3646 22.3659 12.3111 22.3123L5.56107 9.34806C6.73967 7.41948 8.4539 5.81238 10.436 4.68735L15.204 14.9731C15.3111 15.1874 15.4182 15.2409 15.5789 15.2409H18.0432C18.2039 15.2409 18.3111 15.1874 18.4182 14.9731L23.1325 4.68735C25.1146 5.81238 26.8289 7.41948 28.0075 9.34806L21.311 22.3123C21.2574 22.4195 21.204 22.4731 21.1504 22.4731C21.0432 22.4731 21.0432 22.3659 20.9897 22.3123L18.4182 16.6338C18.3111 16.4195 18.2039 16.3659 18.0432 16.3659H15.5789C15.4182 16.3659 15.3111 16.4195 15.2039 16.6338L12.6325 22.3123C12.579 22.3659 12.579 22.4731 12.4719 22.4731ZM16.8111 12.8302C16.704 12.8302 16.704 12.7231 16.6504 12.6695L12.5253 3.77667C13.8646 3.2945 15.3111 3.02668 16.8111 3.02668C18.3111 3.02668 19.7575 3.2945 21.0968 3.77667L16.9718 12.6695C16.9182 12.7231 16.9182 12.8302 16.8111 12.8302ZM16.8111 29.4909C9.57893 29.4909 3.63249 23.4909 3.63249 16.2587C3.63249 14.7588 3.90041 13.3124 4.38248 11.9195L12.0968 26.9196C12.2039 27.1338 12.3111 27.2945 12.579 27.2945C12.8468 27.2945 12.9539 27.1338 13.061 26.9196L16.6504 18.9374C16.704 18.8302 16.7575 18.7766 16.8111 18.7766C16.9182 18.7766 16.9182 18.8837 16.9718 18.9374L20.6146 26.9196C20.7218 27.1338 20.8289 27.2945 21.0968 27.2945C21.3647 27.2945 21.4717 27.1338 21.5789 26.9196L29.2932 11.9195C29.7753 13.2588 30.0432 14.7052 30.0432 16.2587C29.9897 23.4909 24.0432 29.4909 16.8111 29.4909Z"
      fill="#080812"
    />
  </svg>
);

export default Volkswagen;
