const Hybrid = (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.77012 17.3338C7.28703 17.246 6.82234 17.0929 6.36884 16.9027C5.2213 16.4212 4.23512 15.7014 3.41111 14.7443C3.38792 14.7174 3.35592 14.6955 3.34473 14.6452C3.57747 14.6597 3.80482 14.6468 4.03197 14.6263C4.04257 14.6348 4.05516 14.6357 4.06756 14.6348C4.07576 14.6342 4.08436 14.6381 4.09236 14.6334C4.08236 14.6342 4.07416 14.6311 4.06796 14.6228C4.3349 14.5661 4.60204 14.5094 4.85458 14.3991C4.90937 14.3751 4.93416 14.4175 4.96496 14.4454C5.56822 14.9896 6.24247 15.4126 6.9925 15.7012C10.4815 17.0426 14.31 15.1616 15.5196 11.511C15.5515 11.4147 15.5941 11.3832 15.6891 11.3667C16.139 11.2885 16.5633 11.1316 16.957 10.8904C16.9766 10.8784 16.996 10.866 17.0238 10.8486C17.0392 10.919 17.0146 10.9768 17.0014 11.0324C16.325 13.8738 14.7033 15.881 12.0635 16.9629C10.7338 17.5078 9.35056 17.6248 7.93988 17.3735C7.92708 17.3712 7.91529 17.3642 7.90309 17.3592C7.90569 17.3489 7.91968 17.3518 7.92248 17.3416C7.8707 17.358 7.82211 17.3112 7.77012 17.334V17.3338Z"
      fill="#516670"
    />
    <path
      d="M15.2071 3.78365C15.2573 3.83622 15.2031 3.81925 15.1837 3.81904C15.0305 3.81821 14.8778 3.81987 14.725 3.83829C14.3993 3.8772 14.0831 3.95627 13.779 4.08211C13.7178 4.10736 13.6818 4.10177 13.6323 4.05665C12.6595 3.17328 11.5379 2.60203 10.254 2.42527C8.09391 2.12805 6.21954 2.77154 4.65869 4.35055C3.91546 5.10229 3.39078 6.00263 3.05626 7.02094C3.04286 7.06172 3.03226 7.09235 2.98128 7.10042C2.48079 7.17949 2.02289 7.37611 1.59359 7.64891C1.5654 7.63525 1.559 7.61351 1.5716 7.58474C1.66038 7.08531 1.80254 6.60161 1.9925 6.13447C2.91989 3.85402 4.49573 2.27397 6.75202 1.4351C7.08354 1.31175 7.42286 1.21426 7.77138 1.15403C7.81457 1.17328 7.85397 1.14347 7.89556 1.14327C7.88936 1.1323 7.94794 1.16521 7.90455 1.12899C8.18029 1.06068 8.46183 1.02902 8.74356 1.0137C11.2784 0.875856 13.4073 1.77868 15.1379 3.69672C15.1557 3.71659 15.1705 3.73936 15.1867 3.76067C15.1767 3.78924 15.1521 3.79586 15.1297 3.78965C15.0747 3.77454 15.0203 3.79296 14.9781 3.79131C15.0463 3.78737 15.1269 3.79669 15.2073 3.78365H15.2071Z"
      fill="#516670"
    />
    <path
      d="M15.2059 3.78356C15.1077 3.82227 15.0075 3.79081 14.8857 3.8053C14.9517 3.75769 15.0489 3.82806 15.0513 3.70264C15.0731 3.80302 15.1263 3.79102 15.1853 3.76059C15.1921 3.76825 15.1991 3.77591 15.2059 3.78356V3.78356Z"
      fill="#516670"
    />
    <path
      d="M3.3916 14.2095C3.49818 14.1776 3.60455 14.1776 3.71093 14.2095C3.60455 14.2225 3.49798 14.2219 3.3916 14.2095Z"
      fill="#516670"
    />
    <path
      d="M7.77051 17.3337C7.8199 17.2763 7.89288 17.3688 7.94127 17.3047C7.94587 17.3136 7.95427 17.3225 7.95447 17.3314C7.95507 17.373 7.92027 17.349 7.90368 17.3589C7.85769 17.3604 7.8133 17.3515 7.77071 17.3337H7.77051Z"
      fill="#516670"
    />
    <path
      d="M15.2069 4.25778C15.1273 4.28965 15.0476 4.28758 14.9678 4.25902C15.0476 4.24971 15.1271 4.24867 15.2069 4.25778Z"
      fill="#516670"
    />
    <path
      d="M1.57067 7.58447C1.57807 7.60579 1.58547 7.62732 1.59267 7.64863C1.58387 7.66188 1.56788 7.68216 1.55788 7.66623C1.54148 7.64036 1.55228 7.60952 1.57047 7.58447H1.57067Z"
      fill="#516670"
    />
    <path
      d="M4.06822 14.6226C4.08361 14.6252 4.09901 14.628 4.12541 14.6326C4.08461 14.6482 4.05682 14.6509 4.03223 14.6262C4.04422 14.625 4.05622 14.6238 4.06822 14.6226V14.6226Z"
      fill="#516670"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.99583 13.7981C2.42953 14.0478 2.89263 14.1972 3.39251 14.2096L3.39271 14.2098C3.49909 14.1941 3.60566 14.1937 3.71204 14.2098C3.863 14.2183 4.01117 14.1914 4.15754 14.1604C6.00012 13.7704 7.13026 11.9062 6.66997 10.0188C6.22627 8.19885 4.43547 7.10665 2.70967 7.60338C1.44316 7.96787 0.506968 9.09278 0.356203 10.4313C0.200238 11.8162 0.840093 13.1331 1.99583 13.7981ZM5.03885 10.5562L4.93167 10.5473V10.5475C4.90585 10.5335 4.87846 10.5352 4.85127 10.537C4.84223 10.5376 4.83322 10.5382 4.82429 10.5382C4.63714 10.5377 4.45003 10.5377 4.26292 10.5376C4.07581 10.5376 3.88871 10.5375 3.70155 10.5371C3.69262 10.5371 3.6836 10.5377 3.67457 10.5384C3.64739 10.5402 3.62004 10.5421 3.59437 10.5272C3.59444 10.5239 3.59434 10.5205 3.59424 10.5172C3.59403 10.5101 3.59382 10.5031 3.59517 10.4963C3.66441 10.1397 3.73386 9.78315 3.80331 9.42656C3.8496 9.18893 3.89588 8.9513 3.9421 8.71367C3.96538 8.59404 3.98797 8.47431 4.01056 8.35454C4.01998 8.3046 4.0294 8.25466 4.03887 8.20472L4.01688 8.19727L2.06152 11.0668H3.53779L2.76136 13.5029L2.77876 13.513L5.03885 10.5562Z"
      fill="#516670"
    />
    <path
      d="M4.93192 10.5477C4.49722 10.5492 4.06232 10.5506 3.62762 10.5519C3.61162 10.5519 3.59322 10.5533 3.59482 10.5272C4.00613 10.5272 4.41744 10.5268 4.82874 10.5277C4.86354 10.5277 4.90253 10.514 4.93212 10.5475L4.93192 10.5477Z"
      fill="#516670"
    />
    <path
      d="M9.11486 16.1606H9.54876C9.54876 16.1641 9.54876 16.1673 9.54876 16.1707H9.11426C9.11426 16.1673 9.11466 16.1641 9.11486 16.1606V16.1606Z"
      fill="#516670"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.8013 4.75435C16.3146 4.43974 15.7849 4.26361 15.2073 4.25781L15.2075 4.25802C15.1279 4.27458 15.0481 4.27209 14.9683 4.25926C14.6758 4.26112 14.3933 4.32363 14.1171 4.41718C12.5533 4.94725 11.6415 6.57366 11.949 8.28223C12.2669 10.0492 13.9554 11.2565 15.6578 10.9344C17.0045 10.6794 18.0084 9.63544 18.2768 8.21103C18.5259 6.88826 17.9279 5.48248 16.8013 4.75435ZM15.1075 5.17529L15.1076 5.1755C14.9418 5.52562 14.7677 5.84637 14.5736 6.1547C14.4563 6.34094 14.3371 6.526 14.2179 6.71107C14.1377 6.83553 14.0575 6.96001 13.9779 7.08485C13.8056 7.35515 13.634 7.62608 13.5148 7.92744C13.2883 8.50055 13.4343 9.17571 13.8742 9.59607C14.4484 10.1448 15.441 10.2189 16.1268 9.77221C16.7715 9.35226 16.9649 8.49083 16.6911 7.88625C16.5875 7.65775 16.4682 7.43981 16.3344 7.22973C16.2491 7.09567 16.1626 6.96237 16.076 6.82893C15.732 6.29885 15.3866 5.76656 15.1076 5.1755L15.1077 5.17529H15.1075ZM16.2279 9.02511C16.2902 8.92182 16.3228 8.80737 16.3262 8.68463L16.326 8.68442L16.326 8.68362C16.3437 8.58111 16.3467 8.47881 16.3268 8.3763L16.3268 8.37479C16.3248 8.24129 16.2904 8.11669 16.2185 8.00658C16.1373 7.8826 16.0297 7.88467 15.9461 8.00803C15.8797 8.10614 15.8511 8.21977 15.8384 8.33609C15.8124 8.57452 15.815 8.81151 15.9395 9.02531C16.0223 9.1673 16.1429 9.16585 16.2279 9.02511Z"
      fill="#516670"
    />
  </svg>
);

export default Hybrid;
