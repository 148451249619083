const Cross = (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.5079 14.2815L9.81639 8.58998L9.49174 8.26534L9.81639 7.9407L15.511 2.24603L15.5167 2.24039L15.5167 2.24044C15.5996 2.16043 15.6657 2.06471 15.7111 1.95889C15.7566 1.85307 15.7805 1.73925 15.7815 1.62408C15.7825 1.50891 15.7606 1.39469 15.717 1.28809C15.6733 1.18149 15.6089 1.08464 15.5275 1.0032C15.446 0.921761 15.3492 0.857355 15.2426 0.813742C15.136 0.770129 15.0218 0.748183 14.9066 0.749184C14.7914 0.750185 14.6776 0.774113 14.5718 0.819572C14.466 0.86503 14.3703 0.93111 14.2903 1.01395L14.2847 1.01969L14.2847 1.01965L8.59 6.71431L8.26536 7.03895L7.94071 6.71431L2.2461 1.01969C2.24608 1.01968 2.24607 1.01966 2.24605 1.01965C2.0834 0.857066 1.86283 0.765735 1.63286 0.765735C1.4029 0.765735 1.18236 0.857047 1.01971 1.0196C1.01968 1.01963 1.01965 1.01966 1.01961 1.01969M15.5079 14.2815L1.01961 1.01969M15.5079 14.2815C15.6644 14.4448 15.7509 14.6628 15.7489 14.8891C15.7469 15.1165 15.6557 15.3341 15.4949 15.4949C15.3341 15.6557 15.1165 15.7469 14.8891 15.7489C14.6629 15.7509 14.4449 15.6644 14.2815 15.5079L8.59 9.81637L8.26536 9.49173L7.94071 9.81637L2.24916 15.5079C2.08585 15.6644 1.86785 15.7509 1.64162 15.7489C1.41419 15.7469 1.19664 15.6557 1.03582 15.4949C0.875001 15.3341 0.783778 15.1165 0.781802 14.8891C0.779836 14.6628 0.866343 14.4448 1.02279 14.2815L6.71433 8.58998L7.03897 8.26534L6.71433 7.9407L1.01971 2.24608M15.5079 14.2815L1.01971 2.24608M1.01961 1.01969C0.857065 1.18234 0.765753 1.40289 0.765753 1.63284C0.765753 1.86282 0.857084 2.08338 1.01966 2.24603M1.01961 1.01969L1.01966 2.24603M1.01966 2.24603C1.01968 2.24605 1.0197 2.24607 1.01971 2.24608M1.01966 2.24603L1.01971 2.24608"
      fill="#3F3F46"
      stroke="#010044"
      strokeWidth="0.918224"
    />
  </svg>
);

export default Cross;
