const GasStation = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.625 16.5V3.75C2.625 2.25 3.63 1.5 4.875 1.5H10.875C12.12 1.5 13.125 2.25 13.125 3.75V16.5H2.625Z"
      stroke="#7D97A3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.5 16.5H14.25"
      stroke="#7D97A3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.2925 7.49993H9.465C10.245 7.49993 10.8825 7.12493 10.8825 6.08243V5.15993C10.8825 4.11743 10.245 3.74243 9.465 3.74243H6.2925C5.5125 3.74243 4.875 4.11743 4.875 5.15993V6.08243C4.875 7.12493 5.5125 7.49993 6.2925 7.49993Z"
      stroke="#7D97A3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.875 9.75H7.125"
      stroke="#7D97A3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.125 12.0075L16.5 12V7.5L15 6.75"
      stroke="#7D97A3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default GasStation;
