import { VehicleListingType } from '@/lib/schema/inventory/types';
import { useEffect, useState } from 'react';

export interface CachedDataType {
  content: VehicleListingType[];
  size: number;
  total: number;
}

export const useCachedData = () => {
  const [cachedData, setCachedData] = useState<CachedDataType | null>(null);

  useEffect(() => {
    const storedData = sessionStorage.getItem('cachedData');
    if (storedData) {
      setCachedData(JSON.parse(storedData));
    }
  }, []);

  const setCustomData = (data: CachedDataType | null) => {
    setCachedData(data);
    sessionStorage.setItem('cachedData', JSON.stringify(data));
  };

  const clearCustomData = () => {
    setCachedData(null);
    sessionStorage.removeItem('cachedData');
  };

  return {
    cachedData,
    setCustomData,
    clearCustomData,
  };
};
