const Drivetrain = (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.09998 4.75C2.09998 2.67893 3.77891 1 5.84998 1H8.24997C10.321 1 12 2.67893 12 4.75V6.55005H17.362C17.87 5.61478 18.8608 4.97986 20 4.97986C21.1392 4.97986 22.13 5.61478 22.638 6.55005H28V4.75C28 2.67893 29.6789 1 31.75 1H34.15C36.2211 1 37.9 2.67893 37.9 4.75V10.85C37.9 12.9211 36.2211 14.6 34.15 14.6H31.75C29.6789 14.6 28 12.9211 28 10.85V9.05005H22.8035C22.5006 9.84302 21.871 10.4745 21.0792 10.7799V29.1802C21.8842 29.4907 22.5217 30.1383 22.8185 30.9501H28V29.15C28 27.079 29.6789 25.4 31.75 25.4H34.15C36.2211 25.4 37.9 27.079 37.9 29.15V35.25C37.9 37.3211 36.2211 39 34.15 39H31.75C29.6789 39 28 37.3211 28 35.25V33.4501H22.6159C22.1016 34.3634 21.1228 34.9802 20 34.9802C18.8772 34.9802 17.8984 34.3634 17.3841 33.4501H12V35.25C12 37.3211 10.321 39 8.24998 39H5.84998C3.77891 39 2.09998 37.3211 2.09998 35.25V29.15C2.09998 27.079 3.77891 25.4 5.84998 25.4H8.24997C10.321 25.4 12 27.079 12 29.15V30.9501H17.1815C17.435 30.2569 17.9368 29.6834 18.5792 29.3373V10.6228C17.9492 10.2833 17.4544 9.72523 17.1965 9.05005H12V10.85C12 12.9211 10.321 14.6 8.24998 14.6H5.84998C3.77891 14.6 2.09998 12.9211 2.09998 10.85V4.75ZM5.84998 3.5C5.15962 3.5 4.59998 4.05964 4.59998 4.75V10.85C4.59998 11.5404 5.15962 12.1 5.84998 12.1H8.24998C8.94033 12.1 9.49998 11.5404 9.49998 10.85V4.75C9.49998 4.05965 8.94033 3.5 8.24997 3.5H5.84998ZM5.84998 27.9C5.15962 27.9 4.59998 28.4597 4.59998 29.15V35.25C4.59998 35.9404 5.15962 36.5 5.84998 36.5H8.24998C8.94033 36.5 9.49998 35.9404 9.49998 35.25V29.15C9.49998 28.4597 8.94033 27.9 8.24997 27.9H5.84998ZM30.5 4.75C30.5 4.05964 31.0596 3.5 31.75 3.5H34.15C34.8404 3.5 35.4 4.05965 35.4 4.75V10.85C35.4 11.5404 34.8404 12.1 34.15 12.1H31.75C31.0596 12.1 30.5 11.5404 30.5 10.85V4.75ZM31.75 27.9C31.0596 27.9 30.5 28.4597 30.5 29.15V35.25C30.5 35.9404 31.0596 36.5 31.75 36.5H34.15C34.8404 36.5 35.4 35.9404 35.4 35.25V29.15C35.4 28.4597 34.8404 27.9 34.15 27.9H31.75Z"
      fill="#38393A"
    />
  </svg>
);

export default Drivetrain;
