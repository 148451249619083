const IncentiveMoneySign = (
  <svg
    width="325"
    height="120"
    viewBox="0 0 325 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_f_11140_198356)">
      <circle cx="166.5" cy="52.4033" r="102.5" fill="#0EDAB5" />
    </g>
    <path
      d="M167.216 86.5234C161.246 86.5234 156.038 85.5372 151.592 83.5647C147.21 81.5286 143.431 78.5699 140.256 74.6886L147.782 63.6174C151.148 67.5623 154.419 70.3301 157.594 71.9208C160.833 73.5115 164.326 74.3069 168.073 74.3069C170.042 74.3069 171.789 74.0523 173.313 73.5433C174.901 72.9707 176.139 72.1753 177.028 71.1573C177.918 70.1392 178.362 68.9303 178.362 67.5305C178.362 66.5124 178.14 65.5898 177.695 64.7627C177.251 63.8719 176.616 63.1084 175.79 62.4721C174.964 61.7722 173.98 61.1677 172.837 60.6587C171.694 60.086 170.423 59.6088 169.026 59.2271C167.629 58.7817 166.105 58.4317 164.453 58.1772C159.69 57.1592 155.721 55.7275 152.545 53.8823C149.433 52.0371 147.083 49.7465 145.495 47.0105C143.971 44.2745 143.209 41.0295 143.209 37.2754C143.209 34.4122 143.844 31.7716 145.114 29.3538C146.385 26.8723 148.131 24.7408 150.354 22.9592C152.64 21.1776 155.244 19.8096 158.166 18.8552C161.151 17.9008 164.295 17.4236 167.597 17.4236C172.932 17.4236 177.632 18.3144 181.696 20.0959C185.825 21.8139 189.381 24.4863 192.366 28.113L185.507 38.039C183.157 35.303 180.458 33.2351 177.409 31.8353C174.424 30.4355 171.249 29.7355 167.883 29.7355C165.787 29.7355 163.977 30.0219 162.453 30.5945C160.928 31.1035 159.722 31.8671 158.833 32.8851C158.007 33.9032 157.594 35.1439 157.594 36.6074C157.594 37.7527 157.88 38.7707 158.452 39.6615C159.023 40.5523 159.849 41.3476 160.928 42.0475C162.008 42.6838 163.31 43.2565 164.834 43.7655C166.359 44.2745 168.042 44.7199 169.883 45.1017C173.377 45.8016 176.52 46.7242 179.315 47.8695C182.109 49.0148 184.491 50.4146 186.46 52.0689C188.492 53.6596 190.048 55.5685 191.128 57.7954C192.207 59.9588 192.747 62.4085 192.747 65.1445C192.747 69.662 191.668 73.5115 189.508 76.6929C187.349 79.8743 184.364 82.324 180.553 84.0419C176.743 85.6962 172.297 86.5234 167.216 86.5234ZM161.5 94.2542L162.262 50.1601L170.36 53.3097L171.122 94.2542H161.5ZM173.218 53.4051L165.12 50.2555L164.358 10.5518H173.98L173.218 53.4051Z"
      fill="white"
    />
    <circle cx="166.501" cy="52.4032" r="74.6903" stroke="white" />
    <defs>
      <filter
        id="filter0_f_11140_198356"
        x="0"
        y="-114.097"
        width="333"
        height="333"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="32"
          result="effect1_foregroundBlur_11140_198356"
        />
      </filter>
    </defs>
  </svg>
);

export default IncentiveMoneySign;
