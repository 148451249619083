export const PlugInCar = (
  <svg
    width="172"
    height="80"
    viewBox="0 0 172 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Camada_1">
      <g id="Group">
        <path
          id="Vector"
          d="M24.3067 13.4925L16.2467 13.4051L16.3867 0.336182L3.52002 19.1933L11.58 19.2807L11.4534 31.079L24.3067 13.4925Z"
          fill="#32FFC8"
        />
        <path
          id="Vector_2"
          d="M164.98 68.632H38.06C36.6 68.632 35.2867 67.7446 34.74 66.3799C34.74 66.3799 27.1133 48.2219 29.1 43.1664C29.8667 42.3933 56.2867 31.832 66.1067 27.7849C76.2133 23.6236 86.02 22.3395 94.9733 22.1042C106.28 21.8085 117.26 25.7883 125.827 33.2303C128.62 35.6572 130.327 40.511 133.593 40.511C140.987 40.511 154.747 41.5127 160.513 45.9967C162.513 47.5496 166.82 52.4236 167.433 54.8975C168.093 57.553 169.167 62.0774 168.42 65.8017C168.087 67.4488 166.647 68.632 164.98 68.632Z"
          stroke="#2B2B2B"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M55.9333 76.2353C62.7743 76.2353 68.32 70.643 68.32 63.7445C68.32 56.8461 62.7743 51.2538 55.9333 51.2538C49.0923 51.2538 43.5466 56.8461 43.5466 63.7445C43.5466 70.643 49.0923 76.2353 55.9333 76.2353Z"
          fill="#32FFC8"
          stroke="#2B2B2B"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_4"
          d="M141.1 77.8891C147.941 77.8891 153.487 72.2968 153.487 65.3983C153.487 58.4999 147.941 52.9076 141.1 52.9076C134.259 52.9076 128.713 58.4999 128.713 65.3983C128.713 72.2968 134.259 77.8891 141.1 77.8891Z"
          fill="#32FFC8"
          stroke="#2B2B2B"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_5"
          d="M133.34 40.4437H93.3667"
          stroke="#2B2B2B"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_6"
          d="M32.2267 52.7395L37.02 47.9059C37.78 47.1395 37.24 45.8286 36.1667 45.8286H30.6133C29.8733 45.8286 29.3067 46.4942 29.42 47.2337L30.18 52.0673C30.3333 53.0488 31.5267 53.4387 32.22 52.7395H32.2267Z"
          fill="#2B2B2B"
        />
        <path
          id="Vector_7"
          d="M161.707 45.6404L165.44 51.3479C166.033 52.2555 165.24 53.4252 164.193 53.2101L158.76 52.0807C158.033 51.9328 157.613 51.1597 157.873 50.4605L159.58 45.8824C159.927 44.9547 161.173 44.8135 161.713 45.6404H161.707Z"
          fill="#2B2B2B"
        />
        <path
          id="Vector_8"
          d="M40.24 38.2993L30.0867 35.9194"
          stroke="#2B2B2B"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_9"
          d="M84.7267 46.3193H88.2467"
          stroke="#2B2B2B"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_10"
          d="M3 42.3665H21.7867V45.8891C21.7867 51.0186 17.6533 55.1866 12.5667 55.1866H12.22C7.13333 55.1866 3 51.0186 3 45.8891V42.3665Z"
          fill="#32FFC8"
          stroke="#2B2B2B"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_11"
          d="M8.12 36.0807V42.1244"
          stroke="#2B2B2B"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_12"
          d="M16.0333 36.0807V42.1244"
          stroke="#2B2B2B"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_13"
          d="M12.3933 55.1866V59.1194C12.3933 63.7177 16.0866 67.442 20.6466 67.442C25.2066 67.442 28.9 63.7177 28.9 59.1194V47.0454"
          stroke="#2B2B2B"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
  </svg>
);
