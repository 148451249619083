const Grid = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0H7.04V7.04H0V0ZM0 8.96H7.04V16H0V8.96ZM8.96 0H16V7.04H8.96V0ZM8.96 8.96H16V16H8.96V8.96Z"
      fill="#A3ACBA"
    />
  </svg>
);

export default Grid;
