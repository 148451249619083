const CarToLeft = (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="fi:car" clipPath="url(#clip0_3762_7848)">
      <path
        id="Vector"
        d="M4.08366 11.3337H2.75033C2.35033 11.3337 2.08366 11.067 2.08366 10.667V8.66699C2.08366 8.06699 2.55033 7.53366 3.08366 7.40033C4.28366 7.06699 6.08366 6.66699 6.08366 6.66699C6.08366 6.66699 6.95033 5.73366 7.55033 5.13366C7.88366 4.86699 8.28366 4.66699 8.75033 4.66699H13.417C13.817 4.66699 14.1503 4.93366 14.3503 5.26699L15.2837 7.20033C15.3719 7.45781 15.417 7.72813 15.417 8.00033V10.667C15.417 11.067 15.1503 11.3337 14.7503 11.3337H13.417"
        stroke="#A3ACBA"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M12.0837 12.6667C11.3473 12.6667 10.7503 12.0697 10.7503 11.3333C10.7503 10.597 11.3473 10 12.0837 10C12.82 10 13.417 10.597 13.417 11.3333C13.417 12.0697 12.82 12.6667 12.0837 12.6667Z"
        stroke="#A3ACBA"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_3"
        d="M10.75 11.333H6.75"
        stroke="#A3ACBA"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_4"
        d="M5.41667 12.6667C4.68029 12.6667 4.08333 12.0697 4.08333 11.3333C4.08333 10.597 4.68029 10 5.41667 10C6.15305 10 6.75 10.597 6.75 11.3333C6.75 12.0697 6.15305 12.6667 5.41667 12.6667Z"
        stroke="#A3ACBA"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3762_7848">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="matrix(-1 0 0 1 16.75 0)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default CarToLeft;
