const Mazda = (
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7604 16.2979C13.0569 17.2549 14.5884 18.5944 15.9759 21.6079C17.3634 18.5944 18.8469 17.0629 21.1914 16.1059L27.0864 13.9984L27.1239 14.1484C27.2199 14.8174 27.2679 15.6311 27.2679 16.4434C27.2679 24.7684 21.7652 26.4431 15.9759 26.3959C10.1859 26.4431 4.73192 24.7684 4.73192 16.4434C4.73192 15.8209 4.73192 15.1984 4.82792 14.6734L4.91342 14.3209L10.7604 16.3009V16.2979ZM25.8175 10.5251L25.69 10.3166C23.632 7.25436 19.9 6.53661 15.976 6.53661C12.052 6.53661 8.22472 7.30161 6.21547 10.4126L5.99947 10.7876C9.45172 12.7601 13.369 12.7961 15.976 17.7814C18.3707 12.6439 22.3307 12.5711 25.8182 10.5251H25.8175ZM28.6075 8.73795L28.2723 8.35545C25.21 5.0532 20.1873 4.33545 15.976 4.33545C11.7175 4.33545 6.55 5.0532 3.53575 8.54595L3.39325 8.74095C1.9075 10.5087 1 13.0452 1 16.4412C1 26.9187 9.51625 28.5942 15.976 28.5942C22.483 28.5949 31 26.9202 31 16.4427C31 13.0452 30.091 10.5102 28.6075 8.7402V8.73795Z"
      fill="#080812"
    />
  </svg>
);

export default Mazda;
